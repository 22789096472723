import React, { useState } from "react";
import classes from "./Sidebar.module.css";
import Text from "components/common/Text/Text";
import clsx from "clsx";
import { FaCircleCheck } from "react-icons/fa6";

import {
  AddressProveIcon,
  FacialRecognitionIcon,
  NationalIdOrPassportIcon,
  PerosonalINformationIcon,
  protectedIcon,
} from "assets";
import { useTheme } from "ThemeContext/ThemeContext";

const Sidebar = ({ currentLevel }) => {
  const { isDarkTheme } = useTheme();
  return (
    <section
      className={clsx(classes.sidebar, !isDarkTheme && classes.lightTheme)}
    >
      <Text semiBold className={classes.heading}>
        It only takes 3-5 hours to verify your account.
      </Text>

      <div className={classes.tabs}>
        <div
          className={clsx(
            classes.tab,
            currentLevel > 0 && classes.complete,
            currentLevel === 0 && classes.active
          )}
        >
          <PerosonalINformationIcon
            opacity={currentLevel > 0 || currentLevel === 1 ? "1" : 0.5}
            color={currentLevel > 0 ? "#11D080" : currentLevel === 1 ? "#4B73FF" : "#8A8C8D"}
          />
          <p className={classes.text}>Personal information</p>
          {currentLevel > 0 && (
            <FaCircleCheck className={classes.checkmark} color={"#11D080"} />
          )}
        </div>
        <div
          className={clsx(
            classes.tab,
            currentLevel > 1 && classes.complete,
            currentLevel === 1 && classes.active
          )}
        >
          <NationalIdOrPassportIcon
            opacity={currentLevel > 1 || currentLevel === 2 ? "1" : 0.5}
            color={currentLevel > 1 ? "#11D080" : currentLevel === 2 ? "#4B73FF" : "#8A8C8D"}
          />
          <p className={classes.text}>National ID or Passport</p>{" "}
          {currentLevel > 1 && (
            <FaCircleCheck className={classes.checkmark} color={"#11D080"} />
          )}
        </div>
        <div
          className={clsx(
            classes.tab,
            currentLevel > 2 && classes.complete,
            currentLevel === 2 && classes.active
          )}
        >
          <AddressProveIcon
            opacity={currentLevel > 2 || currentLevel === 3 ? "1" : 0.5}
            color={currentLevel > 2 ? "#11D080" : currentLevel === 3 ? "#4B73FF" : "#8A8C8D"}
          />
          <p className={classes.text}>Address Verification</p>{" "}
          {currentLevel > 2 && (
            <FaCircleCheck className={classes.checkmark} color={"#11D080"} />
          )}
        </div>
        {/* <div
          className={clsx(
            classes.tab,
            currentLevel > 3 && classes.complete,
            currentLevel === 3 && classes.active
          )}
        >
          <FacialRecognitionIcon
            opacity={currentLevel > 3 || currentLevel === 4 ? "1" : 0.5}
            color={currentLevel > 3 ? "#11D080" : currentLevel === 4 ? "#4B73FF" : "#8A8C8D"}
          />{" "}
          <p className={classes.text}>Facial Recognition</p>{" "}
          {currentLevel > 3 && (
            <FaCircleCheck className={classes.checkmark} color={"#11D080"} />
          )}
        </div> */}
      </div>
      <div className={classes.tab}>
        <img src={protectedIcon} alt="" />
        <Text semiBold sm colorGrey2>
          Your information on {process.env.REACT_APP_EXCHANGE_NAME} is encrypted.
        </Text>
      </div>
    </section>
  );
};

export default Sidebar;
