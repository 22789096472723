import React, { useEffect, useState } from "react";

import classes from "./Withdraw.module.css";
import Heading from "components/common/Heading/Heading";
import SelectCoin from "components/Wallet/Withdraw/SelectCoin/SelectCoin";
import CommonFaq from "components/Wallet/Deposit/CommonFaq/CommonFaq";
import WithdrawTo from "components/Wallet/Withdraw/WithdrawTo/WithdrawTo";
import BalanceFeesWithdrwalLimit from "components/Wallet/Withdraw/BalanceFeesWithdrwalLimit/BalanceFeesWithdrwalLimit";
import EnterAmount from "components/Wallet/Withdraw/EnterAmount/EnterAmount";
import Button from "components/Button/Button";
import WithdrawVerificationModal from "modals/WithdrawVerification/WithdrawVerificationModal";
import { useSelector } from "react-redux";
import { selectCurrencyPrices } from "../../../redux/currencies";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const faqs = [
  {
    question: "How do I initiate a withdrawal?",
    answer:
      "To initiate a withdrawal, log into your account, navigate to the withdrawal section, select the cryptocurrency you wish to withdraw, enter the amount and the recipient wallet address, and then follow the prompts to complete the withdrawal process.",
  },
  {
    question: "What are the withdrawal fees?",
    answer:
      "Withdrawal fees vary depending on the cryptocurrency you are withdrawing. Fees are adjusted periodically based on the blockchain network congestion. Please check the fees section on the withdrawal page for the most current rates.",
  },
  {
    question: "How long does a withdrawal take?",
    answer:
      "Withdrawal times can vary depending on the cryptocurrency and the current load on its network. Most withdrawals are processed within a few hours, but it can take up to 24-48 hours during periods of high volume or network congestion.",
  },
  {
    question: "Are there withdrawal limits?",
    answer:
      "Yes, withdrawal limits are in place for the security of your account and vary based on your verification level. You can view your current limits by visiting the account settings page. To increase your limits, please complete the necessary verification steps.",
  },
  {
    question: "Why is my withdrawal pending?",
    answer:
      "Withdrawals can be pending for various reasons, including security checks, network congestion, or additional information required for compliance purposes. If your withdrawal is pending for more than 48 hours, please contact support for assistance.",
  },
  {
    question: "Can I cancel a withdrawal?",
    answer:
      "Withdrawals can only be canceled if they are still in the pending state. Once the withdrawal has been processed, it cannot be canceled. If you wish to cancel a pending withdrawal, please go to the withdrawal history page and select the cancellation option.",
  },
  {
    question: "What should I do if my withdrawal has not arrived?",
    answer:
      "If your withdrawal has not arrived within the expected timeframe, please check the transaction on the blockchain to see its status. If the transaction is confirmed on the blockchain but not credited to the recipient wallet, contact the wallet provider. If the transaction is not found on the blockchain, please contact our support team with the transaction details.",
  },
  {
    question: "How can I ensure my withdrawal is secure?",
    answer:
      "Ensure the recipient wallet address is correct before submitting a withdrawal request. Enable two-factor authentication (2FA) on your account for added security. Be cautious of phishing attempts and only access your account through the official website.",
  },
  {
    question: "What cryptocurrencies can I withdraw?",
    answer:
      "You can withdraw any of the cryptocurrencies that are supported on our platform. For a full list of supported coins and tokens, please visit the withdrawal section on our website.",
  },
  {
    question: "How can I track my withdrawal?",
    answer:
      "After initiating a withdrawal, you can track its progress in the withdrawal history section of your account. You will also receive an email notification once the withdrawal is processed, including the transaction hash if applicable.",
  },
];
const Withdraw = () => {
  const [isVerificationModalActive, setIsVerificationModalActive] =
    useState(false);
  const [tokenBalance, setTokenBalance] = useState(0);
  let currencies = useSelector((state) => state.currencies.currencies);
  let networks = useSelector((state) => state.networks.networks);
  let balances = useSelector((state) => state.balances.balances);

  function getSelectedCoinBalance() {
    if (selectedCoin == null) {
      return;
    }
    let balance = balances.filter((balance) => {
      return (
        balance.currency == selectedCoin.id && balance.wallet_type == "Spot"
      );
    });

    setTokenBalance(balance[0].balance);
  }

  const [selectedCoin, setSelectedCoin] = useState(currencies[0]);
  const [networkConfig, setNetworkConfig] = useState(null);
  useEffect(() => {
    getSelectedCoinBalance();
  }, [selectedCoin]);

  const [address, setAddress] = useState("");

  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [selected_coin_networks, set_selected_coin_networks] = useState([]);

  let currencyPrices = useSelector(selectCurrencyPrices);
  const navigate = useNavigate();

  const [amount, setAmount] = useState(0.0);
  function get_coin_networks() {
    setSelectedNetwork(null);
    if (selectedCoin == null) {
      return;
    }
    let ids = [];
    selectedCoin.networks.map((network) => {
      ids.push(network.network_id);
    });

    let full_network = networks.filter((network) => {
      return ids.includes(network.id);
    });
    set_selected_coin_networks(full_network);
  }

  async function getNetworkConfig() {
    if (selectedCoin == null || selectedNetwork == null) {
      return;
    }

    let network_config = selectedCoin.networks.filter((network) => {
      return network.network_id == selectedNetwork.id;
    });

    setNetworkConfig(network_config[0]);
  }

  useEffect(() => {
    getNetworkConfig();
  }, [selectedNetwork]);

  useEffect(() => {
    get_coin_networks();
  }, [selectedCoin]);

  async function gen_otp() {
    if (!address) {
      toast.error("Please enter a valid address");
      return;
    }

    if(amount - networkConfig?.withdrawal_fee  == 0) {
      toast.error("Amount should be greater than withdrawal fee");
      return;
    }

    if(amount <= 0){
      toast.error("Please enter a valid amount");
      return;
    }

    if (amount < networkConfig?.withdrawal_fee) {
      toast.error(`Minimum withdrawal amount is ${networkConfig?.withdrawal_fee} ${selectedCoin?.symbol.toUpperCase()}`);
      return;
    }



    if (address.length < 10) {
      toast.error("Please enter a valid address");
      return;
    }

    if(!selectedNetwork){
      toast.error("Please select a network");
      return;
    }
    let api = process.env.REACT_APP_API;
    let route = "/auth/generate-otp";

    let body = {
      action: "Withdrawal",
      otp_method: "Email",
    };
    try {
      let { data, status } = await axios.post(api + route, body, {
        headers: {
          Authorization: "Bearer " + Cookies.get("auth-token"),
        },
      });
      console.log(data);
      if (status == 200) {
        setIsVerificationModalActive(true);
      }
    } catch (e) {
      return 400;
    }
  }

  async function withdrawAsset(token) {
    let api = process.env.REACT_APP_API;
    let route = "/balance/withdraw/external";

    if (!address) {
      toast.error("Please enter a valid address");
    }

    if (address.length < 10) {
      toast.error("Please enter a valid address");
    }

    let body = {
      address: address,
      network: selectedNetwork.id,
      amount: Number(amount),
      currency: selectedCoin.id,
      mfa: [
        {
          method: "Email",
          data: token,
        },
      ],
    };

    try {
      let { data, status } = await axios.post(api + route, body, {
        headers: {
          Authorization: "Bearer " + Cookies.get("auth-token"),
        },
      });

      if (status == 200) {
        localStorage.setItem("withdrawal-details", JSON.stringify(body));
        navigate("withdraw-success");
      }
      console.log(status);
    } catch (e) {
      let msg = e.response.data.message;
      toast.error(msg);
      console.log(e);
    }
  }

  return (
    <>
      <WithdrawVerificationModal
        action={withdrawAsset}
        isActive={isVerificationModalActive}
        onClose={() => setIsVerificationModalActive(false)}
      />
      <div className={classes.mainWrapper}>
        <Heading heading="Withdraw" border />

        <div className={classes.wrapper}>
          <div className={classes.left}>
            <SelectCoin
              dropdownItems={currencies}
              selectedCoin={selectedCoin}
              setSelectedCoin={setSelectedCoin}
              onSelect={(val) => {
                alert(val);
                setSelectedNetwork(null);
              }}
            />
            <WithdrawTo
              address={address}
              setAddress={setAddress}
              selectedNetwork={selectedNetwork}
              setSelectedNetwork={setSelectedNetwork}
              dropdownItems={selected_coin_networks}
            />
            {selectedNetwork && (
              <>
                <BalanceFeesWithdrwalLimit
                  selectedNetwork={selectedNetwork}
                  networkConfig={networkConfig}
                  tokenBalance={tokenBalance}
                  selectedCoin={selectedCoin}
                />
                <EnterAmount
                  balance={tokenBalance}
                  amount={amount} setAmount={setAmount}
                />{" "}
              </>
            )}

            {selectedNetwork && (
              <div className={classes.spaceBetween}>
                <div className={classes.valueContainer}>
                  <p className={classes.label}>You will receive</p>
                  <p className={classes.value}>
                    {networkConfig?.withdraw_fee_currency ==
                      "WithdrawCurrency" && amount > networkConfig?.withdrawal_fee
                      ? Number((amount - networkConfig?.withdrawal_fee)).toFixed(2)
                      : networkConfig?.withdraw_fee_currency ==
                      "WithdrawCurrency" && amount <= networkConfig?.withdrawal_fee ? 0 : amount.toFixed(2)
                      
                      }{" "}
                    <span className={classes.currency}>
                      {selectedCoin?.symbol.toUpperCase()}
                    </span>{" "}
                    ~{" "}
                    {(
                      currencyPrices?.find(
                        (prices) =>
                          prices?.token == selectedCoin?.id?.toLowerCase()
                      ).price * amount
                    ).toFixed(2)}{" "}
                    USD
                  </p>
                </div>
                <Button
                  btnPrimary
                  size="md"
                  mlAuto
                  onClick={() => {
                    gen_otp();
                  }}
                >
                  Withdraw
                </Button>
              </div>
            )}
          </div>
          <CommonFaq faqs={faqs} />
        </div>
      </div>
    </>
  );
};

export default Withdraw;
