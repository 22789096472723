import { useState } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";

import Section from "components/common/Section/Section";

import {
  discord,
  dropdownArrow,
  facebook,
  github,
  instagram,
  linkArrow,
  linkedin,
  logoFooter,
  telegram,
  x,
  youtube,
} from "assets";

import Dropdown from "./Dropdown/Dropdown";
import classes from "./Footer.module.css";
const Footer = () => {
  const [selectedLanguage, setSelectedLanguage] = useState("English");


  let companyLinks = JSON.parse(process.env.REACT_APP_COMPANY_LINKS);
  let explore = JSON.parse(process.env.REACT_APP_EXPLORE);
  let policies = JSON.parse(process.env.REACT_APP_POLICIES)
  let support = JSON.parse(process.env.REACT_APP_SUPPORT_LINKS)


  let quick_links = [
    {
      to : "/staking",
      title: "Staking"
    },
    {
      to : "/wallet/overview",
      title: "Wallets"
    },
    {
      to : "/markets",
      title: "Markets"
    }
  ]




  return (
    <>
      <Section md className={classes.footer}>
        <div className={classes.header}>
          <Link to="/">
            <img width={175} src={process.env.REACT_APP_LOGO} alt="logo" />
          </Link>
          {/* <Dropdown
            listItems={[
              { name: "English" },
              { name: "Spanish" },
              { name: "Latin" },
              { name: "French" },
              { name: "German" },
              { name: "Farsi" },
              { name: "Russian" },
            ]}
            onSelect={(val) => setSelectedLanguage(val.name)}
          >
            <div className={classes.dropdown}>
              <span>{selectedLanguage}</span>

              <img src={dropdownArrow} alt="arrow" />
            </div>
          </Dropdown> */}
        </div>
        <div className={classes.linksRow}>
          <div className={classes.linkCol}>
            <h5>Company</h5>

            <div className={classes.links}>

              {companyLinks.map((el, idx) => {
                return (
                  <Link
                    to={el.to}
                    key={"footer-company-link-" + idx}
                  >
                    {el.title}
                  </Link>
                );
              })}
              {/* <Link to="https://uns.technology/team"></Link>
              <Link to="">Whitepaper</Link>
              <Link to="">Partnership</Link> */}
              {/* <Link>Announcements</Link>
              <Link>Referral</Link>
              <Link>Security</Link> */}
            </div>
          </div>
          <div className={classes.linkCol}>
            <h5>Quick links</h5>

            <div className={classes.links}>
              {
                quick_links.map((el, idx) => {
                  return (
                    <Link
                      to={el.to}
                      key={"footer-quick-link-" + idx}
                    >
                      {el.title}
                    </Link>
                  );
                })
              }
              {/* <Link to={`/staking`}>Staking</Link>
              <Link to="/wallet/overview">Wallets</Link>

              <Link to="/markets">Markets</Link> */}
            </div>
          </div>
          <div className={classes.linkCol}>
            <h5>Support</h5>

            <div className={classes.links}>
            
            {
                support.map((el, idx) => {
                  return (
                    <Link
                      to={el.to}
                      key={"footer-quick-link-" + idx}
                    >
                      {el.title}
                    </Link>
                  );
                })
              }
              {/* <Link>Submit a Ticket</Link>
              <Link>Fees Structure</Link>
              <Link>Verification Center</Link>
              <Link>Bug Report</Link>
              <Link>Faq</Link> */}
            </div>
          </div>
          <div className={classes.linkCol}>
            <h5>Explore</h5>

            <div className={classes.links}>
              {
                explore.map((el, idx) => {
                  return (
                    <Link
                      to={el.to}
                      key={"footer-explore-link-" + idx}
                    >
                      {el.title}
                    </Link>
                  );
                })
              }
              {/* <Link to={``}>UNS Homepage</Link>
              <Link to={`https://staking.uns.technology`}>UNS Staking</Link>
              <Link to={`https://sale.uns.technology`}>Buy UNS</Link>
              <Link to={`https://uns.technology`} className={classes.seeMore}>
                See More <img src={linkArrow} alt="link arrow" />{" "}
              </Link> */}
            </div>
          </div>
          <div className={classes.linkCol}>
            <h5>Legal & Privacy</h5>


            {/* <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/aml" element={<AML />}></Route>
              <Route
                path="/terms-and-conditions"
                element={<TermsAndConditions />}
              />
              <Route path="/risk-disclosure" element={<RiskDisclosure />} /> */}

            <div className={classes.links}>
            {   
            policies.map((el, idx) => {
              return (
                <Link
                  to={el.to}
                  key={"footer-explore-link-" + idx}
                >
                  {el.title}
                </Link>
              );
            })
           }

            </div>
          </div>
        </div>
        <div className={classes.footNotes}>
          <div className={classes.copyRight}>
            © 2024 {process.env.REACT_APP_EXCHANGE_NAME}. All Rights Reserved.
          </div>
          <div className={classes.social}>
            {[
              { icon: discord, link: "https://discord.gg/5BBkVWWsTr" },
              // { icon: facebook, link: "https://www.google.com" },
              { icon: x, link: "https://twitter.com/_unsofficial_?lang=en" },
              { icon: telegram, link: "https://t.me/unstoken" },
              // { icon: instagram, link: "https://www.google.com" },
              // { icon: youtube, link: "https://www.google.com" },
              // { icon: linkedin, link: "https://www.google.com" },
              // { icon: github, link: "https://www.google.com" },
            ].map((el, idx) => {
              return (
                <Link
                  // to={el.link}
                  onClick={() => window.open(el.link, "_blank")}
                  key={"footer-social-icon-" + idx} className={classes.box}>
                  <img
                    src={el.icon} alt="icon" />
                </Link>
              );
            })}
          </div>
        </div>
      </Section>
    </>
  );
};

export default Footer;
