import React, { useRef, useState } from "react";
import Text from "../../common/Text/Text";
import classes from "./SubmitNidOrPassport.module.css";
import Dropdown from "../Dropdown/Dropdown";

import Button from "components/Button/Button";
import clsx from "clsx";
import Input from "components/common/Input/Input";
import Cookies from "js-cookie";
import axios from "axios";
import { Navigate } from "react-router-dom";

const documentTypes = ["NationalID", "Passport"];

const SubmitNidOrPassport = ({ setStep }) => {
  //DocumentType dropdown
  const documentTypeRef = useRef(null);
  const [documentType, setDocumentType] = useState("Passport");
  const [documentNumber, setDocumentNumber] = useState("");
  const [
    isSelectedDocumentTypeDropdownActive,
    setIsSelectDocumentTypeDropdownActive,
  ] = useState(false);

  //nid
  const [frontSideOfNid, setFrontSideOfNid] = useState(null);
  const [backSideOfNid, setBackSideOfNid] = useState(null);
  //pasport
  const [passportImg, setPassportImg] = useState();

  const [filesArray , setFilesArray] = useState([])
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setFilesArray([...filesArray, file])
    const inputId = event.target.id;



    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (inputId === "uploadFront") {
          setFrontSideOfNid(reader.result);
        } else if (inputId === "uploadBack") {
          setBackSideOfNid(reader.result);
        } else if (inputId === "passportImg") {
          setPassportImg(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };


  async function submitDocuments() {
    let api = process.env.REACT_APP_API + "/kyc/upload_docs?document_type=" + documentType + "&document_number=" + documentNumber;
    let formData = new FormData();
    filesArray.forEach((file) => {
      formData.append("files", file);
    });

    let update = await axios.post(api, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        "Authorization": "Bearer " + Cookies.get("auth-token")
      }
    });

    if (update.status === 200) {
      // setStep((prev) => prev + 1);
      // Navigate("/id-verification-pending")
      window.location.href = "/id-verification-pending"
    }
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <Text xl2 colorWhite semiBold>
          Submit National ID or Passport
        </Text>
        <Text sm>
          Kindly provide the requested information. Please note that your
          information will only be used for verification purposes. Thank you.
        </Text>
      </div>
      <div ref={documentTypeRef}>
        <Dropdown
          label="Document Type"
          styled
          isActive={isSelectedDocumentTypeDropdownActive}
          setActive={setIsSelectDocumentTypeDropdownActive}
          selectedValue={documentType}
          onSelect={(val) => {
            setDocumentType(val);
            setIsSelectDocumentTypeDropdownActive(false);
          }}
          dropdownItems={documentTypes}
          dropdownRef={documentTypeRef}
        />{" "} 
      </div>{" "}

      <div className={classes.inputWrapper}>
        <Text sm semiBold colorGrey2>
          Document Number
        </Text>
        <div className={classes.inputContainer}>
          <Input
            type="text"
            placeholder="Document Number"
            value={documentNumber}
            className={classes.input}
            rootClassName={classes.input}
            onChange={(e) => {
              setDocumentNumber(e.target.value);
            }}
          />{" "}
         
        </div>{" "}
        <Text sm semiBold colorGrey2>
        </Text>{" "}
      </div>{" "}
      {documentType === "National ID" && (
        <div className={classes.nidCardContainer}>
          <div className={classes.nidCard}>
            <div className={classes.uploader}>
              <label htmlFor="uploadFront" className={classes.upload}>
                Upload Front Part Picture of National ID Card
              </label>
              <input
                type="file"
                id="uploadFront"
                onChange={handleImageChange}
              />
              {frontSideOfNid && (
                <img
                  src={frontSideOfNid}
                  alt="Uploaded"
                  className={classes.uploadedImg}
                />
              )}
            </div>
            <div className={classes.uploader}>
              <label htmlFor="uploadBack" className={classes.upload}>
                Upload Back Part Picture of National ID Card
              </label>
              <input type="file" id="uploadBack" onChange={handleImageChange} />
              {backSideOfNid && (
                <img
                  src={backSideOfNid}
                  alt="Uploaded"
                  className={classes.uploadedImg}
                />
              )}
            </div>
          </div>

          <Text sm semiBold colorGrey2>
            Please ensure that the uploaded pictures are clear.
          </Text>
        </div>
      )}{" "}
      {documentType === "Passport" && (
        <div className={classes.passportUploader}>
          <div className={clsx(classes.uploader)}>
            <label htmlFor="passportImg" className={classes.upload}>
              Upload Picture of Your Passport
            </label>
            <input type="file" id="passportImg" onChange={handleImageChange} />
            {passportImg && (
              <img
                src={passportImg}
                alt="Uploaded"
                className={classes.uploadedImg}
              />
            )}
          </div>
        </div>
      )}
      <div className={classes.buttonContainer}>
        {/* <Button
          size="md"
          btnGray
          onClick={() => {
            setStep((prev) => prev - 1);
          }}
        >
          Cancel
        </Button> */}
        <Button
          size="md"
          btnPrimary
          onClick={() => {
            submitDocuments();
            // setStep((prev) => prev + 1);
          }}
          mlAuto
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default SubmitNidOrPassport;
