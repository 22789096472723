import clsx from "clsx";
import Modal from "components/common/Modal/Modal";
import Text2xl from "components/common/Text2xl/Text2xl";
import Input from "components/common/Input/Input";
import Button from "components/Button/Button";
import TextRegular from "components/common/TextRegular/TextRegular";
import { greyTick } from "assets";
import classes from "./SetNewPassword.module.css";
import { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

const SetNewPassword = ({ isActive, onClose, onSave }) => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");


  async function changePassword() {

    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match", {
        toastId: "password-mismatch",
        position: "top-center",
        theme: "dark",
      });
      return;
    }

  let body = {
    password : newPassword
  }
  
    try {
      let api = process.env.REACT_APP_API;
      let route = "/user/user/change-password";

      let url = api + route;

      const { data, status } = await axios.post(
        url,
        body,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("auth-token")}`,
          },
        }
      );

      if (status == 200) {
       onSave();
      }
    } catch (e) {}
  }






  return (
    <Modal isActive={isActive} onClose={onClose} short styled>
      <div className={classes.text}>
        <Text2xl className={classes.title}>Set New Password</Text2xl>
        <TextRegular>
          Enter a strong password for your brandname account. You can use this
          for login to your account.
        </TextRegular>
      </div>

      <div className={classes.inputContainer}>
        <label htmlFor="email">New Password</label>
        <Input
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
      </div>

      <ul className={classes.ul}>
        <li>
          <img src={greyTick} alt="grey tick" /> At least 8 character
        </li>
        <li>
          <img src={greyTick} alt="grey tick" /> At least 1 number
        </li>
        <li>
          <img src={greyTick} alt="grey tick" /> At least 1 upper-case character
        </li>
        <li>
          <img src={greyTick} alt="grey tick" /> At least 1 lower-case character
        </li>
      </ul>

      <div className={clsx(classes.inputContainer, classes.inputContainer2)}>
        <label htmlFor="confirm-password">Confirm Password</label>
        <Input
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </div>

      <div className={classes.btnContainer}>
        <Button btnPrimary size="md" onClick={changePassword}>
          Save Password
        </Button>
      </div>
    </Modal>
  );
};

export default SetNewPassword;
