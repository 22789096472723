import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import classes from "./DatePicker.module.css";
import { FaCalendarDays } from "react-icons/fa6";
import "./DatePicker.css";

const StartDate = ({ placeholder, value, ...rest }) => {
  const CustomDatePickerInput = ({ value, onClick }) => (
    <div className={classes.inputContainer} onClick={onClick}>
      <span>{value ? value : placeholder}</span>

      <FaCalendarDays className={classes.calender} />
    </div>
  );
  return (
    <div className={classes.datePickerRoot}>
      <DatePicker
        selected={value}
        placeholderText={placeholder}
        {...rest}
        customInput={<CustomDatePickerInput />}
      />
    </div>
  );
};

export default StartDate;
