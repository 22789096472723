import React, { useEffect, useState } from "react";
import classes from "./IDVerification.module.css";
import clsx from "clsx";
import Sidebar from "components/IDVerification/Sidebar/Sidebar";
import PerosonalINformation from "components/IDVerification/PersonalInformation/PerosonalInformation";

import SubmitNidOrPassport from "components/IDVerification/SubmitNidOrPassport/SubmitNidOrPassport";
import AddressProve from "components/IDVerification/AddressProve/AddressProve";
import Cookies from "js-cookie";
import axios from "axios";
import { useSelector } from "react-redux";

const IDVerification = () => {
  let [currentLevel, setCurrentLevel] = useState(0);

  const user = useSelector((state) => state.user);

  console.log(user)

  async function getCurrentLevel() {
    let api = process.env.REACT_APP_API + "/kyc/progress";
    let response = await axios.get(api, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("auth-token"),
      },
    });

    if (response.status === 200) {
      console.log(response.data);
      // setCurrentLevel(response.data.progress);

      

      let next = response.data.next_submit - 1;
      console.log("frontend next level", next)
      if (next <= 1) {
        setCurrentLevel(next);
      } else {
        if (user.kyc_level > next) {
          window.location.href = "/id-verification-succesfull";
        } else {
          window.location.href = "/id-verification-pending";
        }
      }
    }
  }

  useEffect(() => {
    getCurrentLevel();
  }, []);
  

  return (
    <div className={clsx("container", classes.wrapper)}>
      <Sidebar currentLevel={currentLevel} />
      {currentLevel === 0 && <PerosonalINformation setStep={setCurrentLevel} />}{" "}
      {currentLevel === 1 && <SubmitNidOrPassport setStep={setCurrentLevel} />}
      {currentLevel === 2 && <AddressProve setStep={setCurrentLevel} />}
    </div>
  );
};

export default IDVerification;
