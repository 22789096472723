import React from "react";

const AddressProveIcon = ({ color, opacity }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
      <g
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        clipPath="url(#a)"
        opacity={opacity}
      >
        <path d="M20 6v12a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2ZM10 16h6" />
        <path d="M11 11a2 2 0 1 0 4 0 2 2 0 0 0-4 0ZM4 8h3M4 12h3M4 16h3" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AddressProveIcon;
