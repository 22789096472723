import React from "react";

const NavNotification = ({ color }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_0_1)">
        <path
          d="M8.3335 6.16667C8.3335 5.72464 8.50909 5.30072 8.82165 4.98816C9.13421 4.67559 9.55813 4.5 10.0002 4.5C10.4422 4.5 10.8661 4.67559 11.1787 4.98816C11.4912 5.30072 11.6668 5.72464 11.6668 6.16667C12.6238 6.61919 13.4397 7.32361 14.0269 8.20442C14.6141 9.08523 14.9505 10.1092 15.0002 11.1667V13.6667C15.0629 14.1848 15.2464 14.6809 15.5358 15.1151C15.8253 15.5493 16.2127 15.9095 16.6668 16.1667H3.3335C3.78761 15.9095 4.175 15.5493 4.46449 15.1151C4.75397 14.6809 4.93745 14.1848 5.00016 13.6667V11.1667C5.0498 10.1092 5.38625 9.08523 5.97345 8.20442C6.56066 7.32361 7.37649 6.61919 8.3335 6.16667Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.5 16.1665V16.9998C7.5 17.6629 7.76339 18.2988 8.23223 18.7676C8.70107 19.2364 9.33696 19.4998 10 19.4998C10.663 19.4998 11.2989 19.2364 11.7678 18.7676C12.2366 18.2988 12.5 17.6629 12.5 16.9998V16.1665"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <circle
        cx="17"
        cy="5"
        r="4"
        fill="#FA4B55"
        stroke={color}
        strokeWidth="2"
      />
      <defs>
        <clipPath id="clip0_0_1">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NavNotification;
