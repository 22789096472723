import { useEffect, useState } from "react";
import clsx from "clsx";

import Input from "components/common/Input/Input";
import Button from "components/Button/Button";
import { ProfileImgPlaceholder, circleCross, circleTick } from "assets";
import classes from "./AccountInfo.module.css";
import { useTheme } from "ThemeContext/ThemeContext";
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo } from "../../../redux/user";

const AccountInfo = () => {
  //theme
  const { isDarkTheme } = useTheme();
  let user = useSelector((state) => state.user.userInfo);
  const [formState, setFormState] = useState({
    username: user?.nickname ? user.nickname : "awesomeuser123",
  });
  const [isAvailable, setIsAvailable] = useState(true);

  // useEffect(() => {
  //   if (formState.username === "grimreaper340") setIsAvailable(true);
  //   else setIsAvailable(false);
  // }, [formState]);

  //user img
  const [uploadedPicture, setUploadedPicture] = useState("");
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const inputId = event.target.id;

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (inputId === "uploadedPicture") {
          setUploadedPicture(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  let dispatch = useDispatch();

  async function setUserName() {
    console.log("setting")
    let api = process.env.REACT_APP_API;
    let route = "/user/user/set-username";

   try {
    let {data, status} = await axios.post(api + route, {
      new_nickname: formState.username
    }, {
      headers : {
        "Authorization" : "Bearer " + Cookies.get("auth-token")
      }
    })

    if (status == 200) {
      toast.success("Username set!")
      dispatch(getUserInfo())
    }


   }catch(e) {
      toast.error("Not set")
   }






  }

  return (
    <>
      <div className={classes.accountInfo}>
        <div>
          <div className={classes.label}>
            <div>Username</div>
            <div
              className={clsx(
                classes.tag,
                isAvailable ? classes.success : classes.danger
              )}
              onClick={() => setIsAvailable(!isAvailable)}
            >
              {/* <span>{isAvailable ? "Available" : "Unavailable"}</span>
              <img src={isAvailable ? circleTick : circleCross} alt="tick" /> */}
            </div>
          </div>
          <Input
            rootClassName={classes.input}
            name="username"
            value={formState.username}
            onChange={(e) =>
              setFormState((prev) => ({ ...prev, username: e.target.value }))
            }
          />
          <div className={clsx(classes.info)}>
            Set a custom name for your profile
          </div>

          <Button 
          onClick={() => {
            setUserName()
          }}
          btnPrimary size="md">
            Update
          </Button>
        </div>

        {/* <div>
          <div className={classes.uploader}>
            {uploadedPicture ? (
              <img
                className={classes.uploadedPicture}
                src={uploadedPicture}
                alt="UploadedImg"
              />
            ) : (
              <label className={classes.placeholder} htmlFor="uploadedPicture">
                <ProfileImgPlaceholder border={isDarkTheme} />
              </label>
            )}

            <div className={classes.right}>
              <label htmlFor="uploadedPicture" className={classes.upload}>
                Upload Picture
              </label>
              <input
                type="file"
                id="uploadedPicture"
                onChange={handleImageChange}
              />
              <div className={classes.note}>
                Note: Your profile picture size must be 128x128.
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default AccountInfo;
