import React, { useRef } from "react";
import clsx from "clsx";
import { MdOutlineLogout } from "react-icons/md";
import { AiOutlineDollarCircle } from "react-icons/ai";
import { FaRegCreditCard } from "react-icons/fa6";

import classes from "./UserDropDown.module.css";
import {
  userImg,
  verified,
  identityIcon,
  logoutIcon,
  paymentMethodIcon,
  referrelIcon,
  settingsIcon,
  tradingFeesIcon,
} from "assets";
import Button from "components/Button/Button";
import IdentityVerification from "components/Dashboard/IdentityVerification/IdentityVerification";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useOnClickOutside from "hooks/useOnClickOutside";
import { useTheme } from "ThemeContext/ThemeContext";

const UserDropDown = ({ isActive, children, onClose }) => {
  const { isDarkTheme } = useTheme();
  const refs = useRef(null);
  let user = useSelector((state) => state.user.userInfo);
  console.log(user);
  let navigate = useNavigate();
  const items = [
    {
      icon: <AiOutlineDollarCircle className={classes.icon} />,
      label: "Wallets",
      to: "/wallet/overview",
    },
    // { icon: identityIcon, label: "Identity Verification" },
    {
      icon: <FaRegCreditCard className={classes.icon} />,
      label: "Staking",
      to: "/staking",
    },
    // { icon: referrelIcon, label: "Referral Program" },
    // { icon: settingsIcon, label: "Account Settings" },
  ];
  useOnClickOutside(refs, onClose);
  return (
    <div className={clsx(classes.dropdown)} ref={refs}>
      {children}
      <div className={clsx(classes.dropdownMain, isActive && classes.active)}>
        <div className={classes.top}>
          <div className={classes.header}>
            <img
              src={`https://api.dicebear.com/7.x/adventurer/svg?seed=${user?.platform_id}`}
              alt="#"
              className={classes.userImg}
            />
            <div className={classes.emailAnduserId}>
              <p className={classes.email}>
                {user?.nickname ? user?.nickname : "No Nickname set"}
              </p>
              <p className={classes.userId}>
                <span>UID:</span> {user?.platform_id}
              </p>
            </div>
          </div>
          <div className={classes.buttonContainer}>
            <Button
              iconBtn
              size="sm"
              className={clsx(classes.verifiedButton, classes.button)}
              onClick={() => {
                onClose();
              }}
            >
              <img src={verified} alt="#" className={classes.veirfied} />
              {user.kyc_level == 0 ? "Unverified" : "Verified"}
            </Button>{" "}
            <Button
              iconBtn
              size="sm"
              btnBlack
              className={clsx(classes.button)}
              onClick={() => {
                navigate("/settings/account-info");
                onClose();
              }}
            >
              Settings
            </Button>
          </div>
        </div>
        <div className={classes.listContainer}>
          {items.map((el, i) => (
            <div
              onClick={() => {
                navigate(el.to);
                onClose();
              }}
              className={classes.list}
              key={i}
            >
              {el.icon}
              <p
                onClick={() => {
                  navigate(el.to);
                }}
                className={classes.label}
              >
                {el.label}
              </p>
            </div>
          ))}
        </div>{" "}
        <Button
          className={clsx(classes.list, classes.logout)}
          onClick={() => {
            Cookies.remove("auth-token");
            navigate("/login");
            window.location.reload();
            onClose();
          }}
        >
          <MdOutlineLogout className={classes.icon} />
          Logout
        </Button>
      </div>
    </div>
  );
};

export default UserDropDown;
