import clsx from "clsx";
import React, { useEffect, useState } from "react";
import OTPInput, { ResendOTP } from "otp-input-react";
import { AnimatePresence, motion } from "framer-motion";

import Section from "components/common/Section/Section";
import PageHeading from "components/common/PageHeading/PageHeading";
import Navigator from "components/common/Navigator/Navigator";
import Button from "components/Button/Button";
import Text2xl from "components/common/Text2xl/Text2xl";
import TextRegular from "components/common/TextRegular/TextRegular";
import { secured } from "assets";
import classes from "./Verification.module.css";
import axios from "axios";
import { useTheme } from "ThemeContext/ThemeContext";
import { toast } from "react-toastify";

const renderButton = (buttonProps) => {
  return (
    <button onClick={() => {}} {...buttonProps} className={classes.resend}>
      {buttonProps.remainingTime !== 0
        ? `Resend Code (${buttonProps.remainingTime})s`
        : "Resend"}
    </button>
  );
};
const renderTime = () => React.Fragment;

const Verification = ({
  sm,
  noResend,
  title = "Login Verification",
  otpInvalid,
  validator,
  setOtpInvalid,
  otp,
  setOTP,
  handler,
  subTitle,
  onVerify,
  redirect,
  isActive = true,
}) => {
  // useEffect(() => {
  //   setOtpInvalid(false);
  // }, [otp]);
  const { isDarkTheme } = useTheme();
  const [isValidated, setIsValidated] = useState(false);
  const [is_clicked, setIsClicked] = useState(false);
  return (
    <Section
      withPadding={!sm}
      xShort
      className={clsx(
        classes.verificationMethod,
        sm && classes.sm,
        !isDarkTheme && classes.lightTheme
      )}
    >
      {sm ? (
        <>
          <Text2xl className={classes.title}>{title}</Text2xl>
          <TextRegular>{subTitle}</TextRegular>
        </>
      ) : (
        <PageHeading heading={title} subHeading={subTitle} />
      )}
      <div className={clsx(classes.inputs)}>
        <OTPInput
          inputClassName={clsx(
            classes.input,
            sm && classes.sm,
            otpInvalid && classes.hasError
          )}
          value={otp}
          onChange={setOTP}
          autoFocus
          OTPLength={6}
          otpType="number"
          disabled={false}
        />
      </div>

      {otpInvalid && (
        <div className={classes.helperError}>
          Please enter valid verification code.
        </div>
      )}

      <div className={clsx(classes.actions, noResend && classes.fullWidth)}>
        <AnimatePresence>
          {!noResend && isActive && (
            <motion.div
              key={"resend-btn"}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <ResendOTP
                renderButton={renderButton}
                renderTime={renderTime}
                action={() => alert("vvv")}
              />
            </motion.div>
          )}
        </AnimatePresence>
        <Button
          // to={redirect || (!onVerify && "/forgot-password")}
          // onClick={onVerify ? onVerify : handler ? handler : null}
          onClick={async (e) => {
            e.preventDefault();
          if (!validator) {
            setIsValidated(true);
            handler(e);

            return;
          } else {
            // validator(otp).then((status) => {
            //   if (status == 200) {
            //     setIsValidated(true);
            //     handler(e);
            //   } else {
            //     setIsValidated(false);
            //     setOtpInvalid(true);
                
            //   }
            // });
            toast.loading("Verifying OTP", {
              position: "top-center",
              theme: "dark",
              toastId: "verifying"
            });
            let res = await validator(otp)

            console.log(res)
            if (res > 0 ){
              setIsValidated(true);
              onVerify(e);
            } else {
              setIsValidated(false);
              setOtpInvalid(true);
              toast.error("Invalid OTP", {
                position: "top-center",
                theme: "dark",
              });
            }
            toast.dismiss("verifying");
          }
           
          }}
          size="md"
          btnPrimary
        >
         {isValidated ? "Verify" : "Verify"}
        </Button>
      </div>

      {sm ? (
        <div className={classes.secured}>
          <img src={secured} alt="secured" /> Secured By {process.env.REACT_APP_EXCHANGE_NAME}.
        </div>
      ) : (
        <Navigator to="/login">Back to Login</Navigator>
      )}
    </Section>
  );
};

export default Verification;