import React from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

import classes from "./SingleRow.module.css";
import clsx from "clsx";
import { useTheme } from "ThemeContext/ThemeContext";
import { useSelector } from "react-redux";
import { parseTickers } from "../../../../../redux/tickers";

const SingleRow = ({
  // time,
  pair,
  type,
  side,
  price,
  // amount,
  // filled,
  // unFilled,
  // triggerCondition,
  initial_quantity,
  executed_quantity,
  total,
  status,

  showDetails,
  setShowDetails,
  id,
  timestamp,
}) => {
  const { isDarkTheme } = useTheme();

  let date = new Date(timestamp / 1000).toLocaleString();
  let markets = useSelector(parseTickers);
  let market = markets.find((market) => market.market_id === pair);
  return (
    <>
      <div
        className={clsx(
          classes.row,
          showDetails && classes.active,
          !isDarkTheme && classes.lightTheme
        )}
      >
        <p className={classes.item}>{pair}</p>
        <p className={classes.item}>{date}</p>
        <p
          className={clsx(
            classes.item,
            classes.status,
            status.toLowerCase() === "completed" && classes.completed,
            status.toLowerCase() === "expired" && classes.expired,
            status.toLowerCase() === "canceled" && classes.canceled
          )}
        >
          {status}
        </p>

        {showDetails === id ? (
          <FaAngleUp
            className={classes.arrow}
            color="#fff"
            onClick={() => {
              if (showDetails) {
                setShowDetails(false);
              } else {
                setShowDetails(true);
              }
            }}
          />
        ) : (
          <FaAngleDown
            className={classes.arrow}
            onClick={() => {
              setShowDetails(id);
            }}
          />
        )}
      </div>
      {showDetails === id && (
        <div className={classes.infoContainer}>
          <div className={classes.spaceBetween}>
            <p className={classes.item}>Type</p>
            <p className={classes.value}>{type}</p>
          </div>
          <div className={classes.spaceBetween}>
            <p className={classes.item}>Side</p>
            <p
              className={clsx(
                classes.value,
                side.toLowerCase() === "buy" && classes.buy,
                side.toLowerCase() === "sell" && classes.sell
              )}
            >
              {side}
            </p>
          </div>
          <div className={classes.spaceBetween}>
            <p className={classes.item}>Price</p>
            <p className={classes.value}>
              {" "}
              {price} {market?.quote_asset}
            </p>
          </div>
          <div className={classes.spaceBetween}>
            <p className={classes.item}>Amount</p>
            <p className={classes.value}>
              {initial_quantity?.toFixed(2)} {market?.base_asset}
            </p>
          </div>
          <div className={classes.spaceBetween}>
            <p className={classes.item}>Filled</p>
            <p className={classes.value}>
              {executed_quantity?.toFixed(2)} {market?.base_asset}
            </p>
          </div>
          <div className={classes.spaceBetween}>
            <p className={classes.item}>Unfilled</p>
            <p className={classes.value}>
              {" "}
              {(initial_quantity - executed_quantity)?.toFixed(2)}{" "}
              {market?.base_asset}
            </p>
          </div>{" "}
          {/* <div className={classes.spaceBetween}>
            <p className={classes.item}>Trigger Condition</p>
            <p className={classes.value}>{triggerCondition}</p>
          </div>{" "} */}
          <div className={classes.spaceBetween}>
            <p className={classes.item}>Total</p>
            <p className={classes.value}>
              {" "}
              {total.toFixed(2)} {market?.quote_asset}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default SingleRow;
