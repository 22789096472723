import React, { useState } from "react";
import classes from "./SpotPage.module.css";
import Toolbar from "components/Spot/Toolbar/Toolbar";
import History from "components/Spot/History/History";

import OrderBookRecentTrades from "components/Spot/OrderBookRecentTrades/OrderBookRecentTrades";
import Trades from "components/Spot/Trades/Trades";
import Pairs from "components/Spot/Pairs/Pairs";
import BuySell from "components/Spot/BuySell/BuySell";
import Button from "components/Button/Button";
import BuyModal from "modals/SpotMobileUi/BuySellModal/BuySellModal";
import BuySellModal from "modals/SpotMobileUi/BuySellModal/BuySellModal";
import MobileLayoutBuySell from "components/Spot/BuySell/MobileLayoutBuySell/MobileLayoutBuySell";
import clsx from "clsx";
import OrderBook from "components/Spot/OrderBookRecentTrades/OrderBook/OrderBook";
import RecentTrades from "components/Spot/OrderBookRecentTrades/RecentTrades/RecentTrades";
import TradingChart from "components/Spot/Chart/TradingChart";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { TVChartContainer } from "components/TVChartContainer";
import { parseTickers } from "../../redux/tickers";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { useTheme } from "ThemeContext/ThemeContext";
import useWebSocket from "react-use-websocket";
import { fetchBalance } from "../../redux/balance";

const SportPage = () => {
  const [amount, setAmount] = useState(1);
  const { isDarkTheme } = useTheme();
  let { market_pair } = useParams();
  let [bids, setBids] = useState([]);
  let [asks, setAsks] = useState([]);
  let [tickers, setTickers] = useState();
  let [recentTrades, setRecentTrades] = useState([]);


  const [amountPrecision, setAmountPrecision] = useState(3);
  const [pricePrecision, setPricePrecision] = useState(8);

  // let dispatch = useDigit

  let [pair, setPair] = useState(market_pair);

  let [bidSideAmount, setBidSideAmount] = useState();
  let [askSideAmount, setAskSideAmount] = useState();

  let [price, setPrice] = useState();

  let [openOrders, setOpenOrders] = useState([]);
  let [tradeHistory, setTradeHistory] = useState([]);
  let [orderHistory, setOrderHistory] = useState([]);

  let [orderbookws, setOrderBookWs] = useState(null);
  let [historyws, setHistoryWs] = useState(null);

  async function resetOrderBook() {
    setBids([]);
    setAsks([]);
  }

  async function resetHistory() {
    setOpenOrders([]);
    setTradeHistory([]);
    setOrderHistory([]);
  }

  async function setMarketPair() {
    if (!market_pair) {
      setPair("UNSUSDT");
    }
  }

  useEffect(() => {
    setMarketPair();
  }, []);

  let markets = useSelector(parseTickers);
  let market = markets.find((market) => market?.symbol == pair);

  const socketUrl = process.env.REACT_APP_WSS + "/ws/subscribe";
  let subscription_message = JSON.stringify({
    operation: "subscribe",
    channel: ["marketdata", "orders"],
    interval: 2,
    market: pair,
    topics: [
      "tickers",
      "orderbook",
      "trades",
      "open_orders",
      "trade_history",
      "order_history",
    ],
    auth_token: Cookies.get("auth-token"),
  });

  const {
    sendMessage,
    sendJsonMessage,
    lastMessage,
    lastJsonMessage,
    readyState,
    getWebSocket,
  } = useWebSocket(socketUrl, {
    onOpen: () => {
      sendMessage(subscription_message);
      //
    },

    onMessage: (event) => {
      console.log(event)
      pushBook(event);
      pushHistory(event);
    },
    //Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: (closeEvent) => true,

    onError: (event) => {
      alert("Error");
    },
  });

  // async function fetchBookFromWs() {
  //   let socket = new WebSocket(process.env.REACT_APP_WSS + "/ws/subscribe");
  //   let random = Math.random().toFixed(0);
  //   socket.onopen = function (event) {
  //     socket.send(
  //       JSON.stringify({
  //         sub_id: random,
  //         operation: "subscribe",
  //         channel: ["marketdata", "orders"],
  //         interval: 2,
  //         market: pair,
  //         topics: [
  //           "tickers",
  //           "orderbook",
  //           "trades",
  //           "open_orders",
  //           "trade_history",
  //           "order_history",
  //         ],
  //         auth_token: Cookies.get("auth-token"),
  //       })
  //     );
  //   };

  //   socket.onmessage = function (event) {

  //   };
  // }

  async function pushBook(event) {
    let data = JSON.parse(event.data);
    if (data.channel.toLowerCase() === "marketdata") {
      if (data.topic.toLowerCase() === "orderbook") {
        if (data.reset == true) {
          resetHandler(data);
        } else {
          incrementHandler(data);
        }
      }

      if (data.topic == "tickers") {
        if (data?.ticker?.market_id != pair) {
          return;
        }
        if (data.reset === true) {
          setTickers(data?.ticker);
        } else {
          setTickers(data?.data);
        }
      }

      if (data.topic === "trades") {
        if (data.reset === true) {
          let trades = data?.trades;
          let filtered = trades.filter((trade) => trade.pair == pair);
          setRecentTrades(filtered);
        } else {
          let trades = data?.data;
          setRecentTrades((prev) => {
            let new_trades = [...prev, ...trades];
            // filter out where pair is not equal to current pair

            let filtered = new_trades.filter(
              (new_trade) => new_trade.pair == pair
            );
            return filtered;
          });
        }
      }
    }
  }
  async function resetHandler(data) {
    let orders = data?.orders;

    let bids = orders.filter((order) => order.side === "Bid");
    let asks = orders.filter((order) => order.side === "Ask");

    // highest bids first
    bids.sort((a, b) => b.price - a.price);
    asks.sort((a, b) => a.price - b.price);

    setBids(bids);
    setAsks(asks);
  }
  async function incrementHandler(data) {
    if (data.pair != pair) {
      console.error("Log error");
      return;
    }

    if (data.data.length == 0) {
      return;
    }
    data.data.forEach((order) => {
      let price = order.price;
      let quantity = order.quantity;
      let total = order.total;

      if (order.side == "Bid") {
        setBids((prev) => {
          let new_orders = [...prev];
          let exists = new_orders.find((el) => el.price == price);
          if (quantity == 0) {
            new_orders = new_orders.filter((el) => el.price != price);
            new_orders.sort((a, b) => b.price - a.price);
            return new_orders;
          }

          if (exists) {
            exists.amount = quantity;
            exists.total = total;
          } else {
            new_orders.push({
              price,
              amount: quantity,
              total,
            });
          }
          new_orders.sort((a, b) => b.price - a.price);
          return new_orders;
        });
      }

      if (order.side == "Ask") {
        setAsks((prev) => {
          let new_orders = [...prev];
          let exists = new_orders.find((el) => el.price == price);
          if (quantity == 0) {
            new_orders = new_orders.filter((el) => el.price != price);
            new_orders.sort((a, b) => a.price - b.price);
            return new_orders;
          }

          if (exists) {
            exists.amount = quantity;
            exists.total = total;
          } else {
            new_orders.push({
              price,
              amount: quantity,
              total,
            });
          }

          new_orders.sort((a, b) => a.price - b.price);
          return new_orders;
        });
      }
    });
  }
  async function pushHistory(event) {
    let data = JSON.parse(event.data);
    if (data.channel.toLowerCase() === "orders") {
      if (data.topic === "open_orders") {
        if (data.data) {
          // setOpenOrders(prevOrders => [...prevOrders, ...data.data]);
          let initial_quantity = data.data.initial_quantity / Math.pow(10, 8);
          let executed_quantity = data.data.executed_quantity / Math.pow(10, 8);
          let total = (data.data.price / Math.pow(10, 8)) * initial_quantity;

          let status_of_order = data.data.status;
          if (status_of_order.toLowerCase() == "cancelled") {

            console.log(openOrders)
            let new_orders = openOrders.filter(
              (order) => order.order_id != data.data.order_id
            );
            console.log("Removing order", data.data);

            setOpenOrders(new_orders);
            console.log(new_orders)
            return;
          }

          let new_order = {
            ...data.data,
            price: data.data.price / Math.pow(10, 8),
            amount: initial_quantity - executed_quantity,
            initial_quantity: initial_quantity,
            executed_quantity: executed_quantity,
            total: total,
            type: data.data.order_type,
          };

          setOpenOrders((prevOrders) => {
            // just push the new order
            let new_orders = [...prevOrders, new_order];
            let sorted_by_highest_timestamp = new_orders.sort(
              (a, b) => b.timestamp - a.timestamp
            );
            return sorted_by_highest_timestamp;
          });
        }

        // push data
        if (data.reset === true) {
          console.log(data.orders)
          setOpenOrders(data.orders);
        }
      }

      if (data.topic === "trade_history") {
        if (data.reset === true) {
          setTradeHistory(data.trades);
        }
      }

      if (data.topic === "order_history") {
        if (data.reset === true) {
          setOrderHistory(data.orders);
        }
      }
    }
  }

  // useEffect(() => {
  //   fetchBookFromWs();
  // }, [pair]);

  const tabs = ["Chart", "Order Book", "Recent Trd.", "Pairs"];

  const [activeTab, setActiveTab] = useState("Chart");
  const polygonIoApiKey = "IR3qS2VjZ7kIDgnlqKxSmCRHqyBaMh9q";
  return (
    <>
      <div
        className={clsx(classes.wrapper, !isDarkTheme && classes.lightTheme)}
      >
        <Toolbar tickers={tickers} pair={pair} />
        <div className={classes.dekstopView}>
          <OrderBookRecentTrades
            pricePrecision={pricePrecision}
            amountPrecision={amountPrecision}
            bids={bids}
            asks={asks}
            tickers={tickers}
            market={market}
            bidSideAmount={bidSideAmount}
            setAskSideAmount={setAskSideAmount}
            askSideAmount={askSideAmount}
            setBidSideAmount={setBidSideAmount}
            setPrice={setPrice}
          />

          {/* <div className={classes.middle}>
            <TradingChart />
            <BuySell askSideAmount={askSideAmount} pair={pair} />
          </div> */}
          <div className={classes.right}>
            <div className={classes.chartAndPairs}>
              {/* <TVChartContainer pair={pair} /> */}
              <TradingChart polygonIoApiKey={polygonIoApiKey} pair={pair} />
              <Pairs setPair={setPair} />
            </div>
            <div className={classes.buySellAndTrading}>
              <BuySell
                pair={pair}
                price={price}
                setPrice={setPrice}
                market={market}
                bidSideAmount={bidSideAmount}
                setAskSideAmount={setAskSideAmount}
                askSideAmount={askSideAmount}
                setBidSideAmount={setBidSideAmount}
              />
              <Trades recentTrades={recentTrades} market={market} />
            </div>
          </div>
        </div>
        <div className={classes.mobileComponents}>
          <div className={classes.tabs}>
            {tabs.map((tab, i) => (
              <p
                className={clsx(
                  classes.tab,
                  tab.toLowerCase() === activeTab.toLowerCase() &&
                  classes.activeTab
                )}
                key={i}
                onClick={() => setActiveTab(tab)}
              >
                {tab}
              </p>
            ))}
          </div>{" "}
          <div className={classes.line}></div>
          {/* {activeTab === "Chart" && <TVChartContainer pair={pair} />} */} *{" "}
          {activeTab === "Chart" && (
            <TradingChart polygonIoApiKey={polygonIoApiKey} pair={pair} />
          )}
          {activeTab === "Order Book" && (
            <OrderBook
              pricePrecision={pricePrecision}
              amountPrecision={amountPrecision}
              setPrice={setPrice}
              bids={bids}
              asks={asks}
              tickers={tickers}
              market={market}
              bidSideAmount={bidSideAmount}
              setAskSideAmount={setAskSideAmount}
              askSideAmount={askSideAmount}
              setBidSideAmount={setBidSideAmount}
            />
          )}
          {activeTab === "Recent Trd." && (
            <Trades recentTrades={recentTrades} market={market} />
          )}
          {activeTab === "Pairs" && <Pairs setPair={setPair} />}
        </div>
        <div className={classes.tablet}>
          <BuySell
            price={price}
            setPrice={setPrice}
            pair={pair}
            bidSideAmount={bidSideAmount}
            setAskSideAmount={setAskSideAmount}
            askSideAmount={askSideAmount}
            setBidSideAmount={setBidSideAmount}
          />
        </div>
        <MobileLayoutBuySell
          price={price}
          setPrice={setPrice}
          pair={pair}
          bidSideAmount={bidSideAmount}
          setAskSideAmount={setAskSideAmount}
          askSideAmount={askSideAmount}
          setBidSideAmount={setBidSideAmount}
        />
        <History
          pair={pair}
          setOpenOrders={setOpenOrders}
          openOrders={openOrders}
          tradeHistory={tradeHistory}
          orderHistory={orderHistory}
        />
      </div>

      {/* <MobileLayoutBuySell /> */}
    </>
  );
};

export default SportPage;
