import Modal from "components/common/Modal/Modal";
import Text2xl from "components/common/Text2xl/Text2xl";
import TextRegular from "components/common/TextRegular/TextRegular";
import Input from "components/common/Input/Input";
import Button from "components/Button/Button";
import classes from "./SetAntiPhishingCode.module.css";
import { greyTick } from "assets";
import { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { getUserInfo } from "../../../../src/redux/user";

const SetAntiPhishingCode = ({ isActive, onClose, onSave }) => {

  const [antiPushingcode, setAntiPushingCode] = useState("");

  const dispatch = useDispatch();
  async function saveAntiCode() {

  let body = {
    new_phishing_code : antiPushingcode
  }
  
    try {
      let api = process.env.REACT_APP_API;
      let route = "/user/user/set-phishing-code";

      let url = api + route;

      const { data, status } = await axios.post(
        url,
        body,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("auth-token")}`,
          },
        }
      );

      if (status == 200) {
        dispatch(getUserInfo());
       onSave();
      }
    } catch (e) {}
  }

  return (
    <Modal isActive={isActive} onClose={onClose} short styled>
      <div className={classes.text}>
        <Text2xl className={classes.title}>Set Anti Phishing Code</Text2xl>
        <TextRegular>
          Choose a unique code to protect your account. Don’t share this code
          with others.
        </TextRegular>
      </div>

      <div className={classes.inputContainer}>
        <label htmlFor="phishing">Enter anti phishing code</label>
        <Input
          id="phishing"
          placeholder=""
          value={antiPushingcode}
          onChange={(e) => setAntiPushingCode(e.target.value)}
        />
      </div>

      <ul className={classes.ul}>
        <li>
          <img src={greyTick} alt="tick" /> Code should be 8-20 character
        </li>
      </ul>

      <div className={classes.btnContainer}>
        <Button btnPrimary size="md" onClick={saveAntiCode}>
          Save Code
        </Button>
      </div>
    </Modal>
  );
};

export default SetAntiPhishingCode;
