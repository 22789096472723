import Modal from "components/common/Modal/Modal";
import Text2xl from "components/common/Text2xl/Text2xl";
import TextRegular from "components/common/TextRegular/TextRegular";
import Input from "components/common/Input/Input";
import Button from "components/Button/Button";
import classes from "./SetNewMail.module.css";
import { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { getUserInfo } from "../../../redux/user";
import { toast } from "react-toastify";

const SetNewMail = ({ isActive, onClose, onSave }) => {
  const [newEmail, setNewEmail] = useState("");

  // init_new_email_for_account

  let dispatch = useDispatch();
  async function initQueueMail() {
    
      try {
        let api = process.env.REACT_APP_API;
        let route = "/user/user/init-queue-email";
  
        let url = api + route;
  
        const { data, status } = await axios.post(
          url,
          {
            email : newEmail
          },
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("auth-token")}`,
            },
          }
        );
  
        if (status == 200) {
          dispatch(getUserInfo());
         onSave();
        }
      } catch (e) {
        toast.error("Error while setting new email", {
          toastId: "error-setting-new-email",
          position: "top-center",
          theme: "dark",
        });
      }
    }
  return (
    <Modal isActive={isActive} onClose={onClose} short styled>
      <div className={classes.text}>
        <Text2xl className={classes.title}>Set new Email</Text2xl>
        <TextRegular>
          Try to use private email to protect your account. Don’t share your
          email with others.
        </TextRegular>
      </div>

      <div className={classes.inputContainer}>
        <label htmlFor="email">Enter New Email</label>
        <Input
          placeholder="your-mail@example.com"
          value={newEmail}
          onChange={(e) => setNewEmail(e.target.value)}
          wFull
        />
      </div>

      <div className={classes.btnContainer}>
        <Button btnPrimary size="md" onClick={initQueueMail}>
          Save New Email
        </Button>
      </div>
    </Modal>
  );
};

export default SetNewMail;
