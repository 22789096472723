import Modal from "components/common/Modal/Modal";
import Button from "components/Button/Button";
import { copy, qrCode } from "assets";
import classes from "./ScanCode2.module.css";
import axios from "axios";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useState } from "react";
const ScanCode2 = ({ isActive, onClose, onContinue }) => {
  const [is_generated, setIsGenerated] = useState(false);
  const [url, setUrl] = useState("");
  const [code, setCode] = useState("");

  async function get_2fa_info() {
    let api = process.env.REACT_APP_API;
    let route = "/auth/initiate-totp-binding";

    let body = {
      action: "Settings",
      otp_method: "Email",
    };
    try {
      let { data, status } = await axios.get(api + route, {
        headers: {
          Authorization: "Bearer " + Cookies.get("auth-token"),
        },
      });
      console.log(data);
      if (status == 200) {
        setIsGenerated(true);
        setCode("data:image/png;base64," + data.code);

        const secretStartIndex = data.url.indexOf("secret=") + 7;
        const secretEndIndex = data.url.indexOf("&", secretStartIndex);
        const secret = data.url.substring(
          secretStartIndex,
          secretEndIndex !== -1 ? secretEndIndex : data.url.length
        );
        setUrl(secret);
      }
    } catch (e) {
      return 400;
    }
  }

  useEffect(() => {
    console.log(is_generated);
  }, [is_generated]);
  return (
    <Modal
      className={classes.scanModal}
      isActive={isActive}
      onClose={onClose}
      short
      styled
    >
      {is_generated && (
        <>
          <h3>Scan QR Code</h3>
          <p>Scan this qr-code with your google authenticator apps. </p>
        </>
      )}

      {!is_generated && (
        <>
          <h3>Generate Qr</h3>
          <p>Click the generate button to generate Qr Code for Binding </p>
        </>
      )}

      {is_generated && (
        <div className={classes.code}>
          <img src={code} alt="qr code" />

          <div className={classes.copy}>
            {url.slice(0, 6) + "..." + url.slice(-6)}
            <img
              onClick={() => {
                navigator.clipboard.writeText(url);
              }}
              src={copy}
              alt="copy"
            />
          </div>
        </div>
      )}

      {is_generated && (
        <p className={classes.short}>
          Facing problem to scanning the QR code. Manually enter this code into
          your Google Authenticator app.{" "}
        </p>
      )}

      <div className={classes.buttons}>
        <Button black200 size="md" onClick={onClose}>
          Cancel
        </Button>
        <Button
          btnPrimary
          size="md"
          onClick={() => {
            if (!is_generated) {
              get_2fa_info();
            } else {
              onContinue();
            }
          }}
        >
          {is_generated ? "Continue" : "Generate"}
        </Button>
      </div>
    </Modal>
  );
};

export default ScanCode2;
