import clsx from "clsx";
import classes from "./Switch.module.css";
import { useTheme } from "ThemeContext/ThemeContext";

const Switch = ({ mlAuto, ...rest }) => {
  const { isDarkTheme } = useTheme();
  return (
    <label
      className={clsx(
        classes.switch,
        mlAuto && classes.mlAuto,
        !isDarkTheme && classes.lightTheme
      )}
    >
      <input {...rest} type="checkbox" />
      <span className={classes.slider}></span>
    </label>
  );
};

export default Switch;
