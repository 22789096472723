import React, { useRef, useState } from "react";
import Text from "../../common/Text/Text";
import classes from "./AddressProve.module.css";
import Dropdown from "../Dropdown/Dropdown";

import Button from "components/Button/Button";
import Input from "components/common/Input/Input";
import { useNavigate } from "react-router-dom";

const documentTypes = [ "Utility Bill"];

const AddressProve = ({ setStep }) => {
  const navigate = useNavigate();
  //DocumentType dropdown
  const documentTypeRef = useRef(null);
  const [documentType, setDocumentType] = useState("Utility Bill");
  const [
    isSelectedDocumentTypeDropdownActive,
    setIsSelectDocumentTypeDropdownActive,
  ] = useState(false);
  //citytown postalCode
  const [citytown, setCityTown] = useState("");
  const [postalCode, setPostalCode] = useState(" ");

  //street address
  const [streetAddress, setStreetAddress] = useState("");

  //document
  const [documentImg, setDocumentImg] = useState(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const inputId = event.target.id;

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (inputId === "document") {
          setDocumentImg(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <Text xl2 colorWhite semiBold>
          Address Verification
        </Text>
        <Text sm>
          Kindly provide the requested information. Please note that your
          information will only be used for verification purposes. Thank you.
        </Text>
      </div>
      <div ref={documentTypeRef}>
        <Dropdown
          label="Document Type"
          styled
          isActive={isSelectedDocumentTypeDropdownActive}
          setActive={setIsSelectDocumentTypeDropdownActive}
          selectedValue={documentType}
          onSelect={(val) => {
            setDocumentType(val);
            setIsSelectDocumentTypeDropdownActive(false);
          }}
          dropdownItems={documentTypes}
          dropdownRef={documentTypeRef}
        />{" "}
      </div>{" "}
      <div className={classes.inputContainer}>
        <Input
          type="text"
          label="City / Town"
          value={citytown}
          rootClassName={classes.input}
          onChange={(e) => {
            setCityTown(e.target.value);
          }}
        />{" "}
        <Input
          type="text"
          label="Postal Code"
          value={postalCode}
          rootClassName={classes.input}
          onChange={(e) => {
            setPostalCode(e.target.value);
          }}
        />
      </div>
      <Input
        type="text"
        label="Street Address"
        value={streetAddress}
        rootClassName={classes.input}
        onChange={(e) => {
          setStreetAddress(e.target.value);
        }}
      />
      <div className={classes.nidCardContainer}>
        <div className={classes.uploader}>
          <label htmlFor="document" className={classes.upload}>
            Upload Picture <br /> of Your Document
          </label>
          <input type="file" id="document" onChange={handleImageChange} />
          {documentImg && (
            <img
              src={documentImg}
              alt="Uploaded"
              className={classes.uploadedImg}
            />
          )}
        </div>

        <Text sm semiBold colorGrey2>
          Please ensure that your document picture is clear.
        </Text>
      </div>
      <div className={classes.buttonContainer}>
        {/* <Button
          size="md"
          btnGray
          onClick={() => {
            setStep((prev) => prev - 1);
          }}
        >
          Cancel
        </Button> */}
        <Button
          size="md"
          btnPrimary
          onClick={() => {
            navigate("/id-verification-succesfull");
          }}
          mlAuto
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default AddressProve;
