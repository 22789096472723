import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

export const fetchBalance = createAsyncThunk(
  "balance-fetch",
  async (args, { rejectWithValue }) => {
    try {
      let base_url = process.env.REACT_APP_API;
      let route = "/balance/balances";
      let data = await axios.get(base_url + route, {
        headers: {
          Authorization: "Bearer " + Cookies.get("auth-token"),
        },
      });
      let balances = data?.data?.data;
      return balances;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const balanceSlice = createSlice({
  name: "balances",
  initialState: {
    balances: [],

    isLoading: false,
    isFailed: false,
  },
  reducers: {},
  // extra reducer use builder callback
  extraReducers: (builder) => {
    builder.addCase(fetchBalance.pending, (state, action) => {
      state.isLoading = true;
      state.isFailed = false;
    });
    builder.addCase(fetchBalance.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isFailed = false;
      state.balances = action.payload;
    });
    builder.addCase(fetchBalance.rejected, (state, action) => {
      state.isLoading = false;
      state.isFailed = true;
    });
  },
});

export default balanceSlice.reducer;
