import React from "react";
import classes from ".//BuySellFromAnyWhere.module.css";
import { AppStore, buySellYourCrypto, PlayStore, qrCode } from "assets";
import Button from "components/Button/Button";
import { useTheme } from "../../../ThemeContext/ThemeContext";
import clsx from "clsx";

const BuySellFromAnyWhere = () => {
  const { isDarkTheme } = useTheme();
  return (
    <div className={clsx(classes.wrapper, !isDarkTheme && classes.lightTheme)}>
      <img src={process.env.REACT_APP_APP_IMAGE} alt="#" className={classes.img} />
      <div className={classes.textContainer}>
        <h2 className={classes.heading}>
          Buy, sell and trade your <br />{" "}
          <span className={classes.highlight}>crypto</span> from anywhere.{" "}
        </h2>
        <p className={classes.text}>
          Enjoy the flexibility to engage in crypto transactions whenever and
          wherever you need.
        </p>

        <div className={classes.downloadFrom}>
          <img src={qrCode} alt="#" className={classes.qrCode} />

          <div className={classes.buttonContainer}>
            <p className={classes.label}>Download app</p>
            <Button
              className={classes.button}
              onClick={() => {
                alert("iOS app will be available soon");
                // setIsQrModalActive(true)
              }}
              btnBlack
              size="lg"
            >
              <AppStore color={isDarkTheme ? "#fff" : "#000"} />
              <div className={classes.box}>
                <h5>Download from the</h5>
                <h6>App Store</h6>
              </div>
            </Button>
            <Button
              className={classes.button}
              onClick={() => {
                alert("Android app will be available soon");
                // setIsQrModalActive(true)
              }}
              btnBlack
              size="lg"
            >
              <PlayStore color={isDarkTheme ? "#fff" : "#000"} />
              <div className={classes.box}>
                <h5>GET IT ON</h5>
                <h6>Google Play</h6>
              </div>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuySellFromAnyWhere;
