import React from "react";

import Verification from "components/Verification/Verification";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { getUserInfo } from "../../redux/user";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";


const GoogleAuthenticatorVerification = ({dispatchAll}) => {
  let Navigate = useNavigate(); 
  let dispatch = useDispatch();
  const [loginData, setLoginData] = useState({});
  const [otpInvalid, setOtpInvalid] = useState(false);
  const [OTP, setOTP] = useState("");

  const navigate = useNavigate();
  async function loginHandler(e) {
    e.preventDefault();
    let api = process.env.REACT_APP_API;
    let route = "/auth/login";
    let url = api + route;

    let body = {
      ...loginData,
      mfa: [
        {
          method: "TOTP",
          data: OTP
        }
      ]
    }


    try {
      let { data, status } = await axios.post(url, body);
      let token = data.token;

      if (status == 200) {
        if (token) {
          Cookies.set("auth-token", token);
          dispatchAll();
          dispatch(getUserInfo());
          Navigate("/dashboard");
        }

      } else {
        toast.error("Invalid OTP", {
          position: "top-center",
          theme: "dark",
        
        });
        setOTP("");
        setOtpInvalid(true);
      }

    } catch (e) {
      toast.error("Invalid OTP", {
        position: "top-center",
        theme: "dark",
      
      });

      setOTP("");
      setOtpInvalid(true);
    }
  }
  async function getLoginData() {
    let loginData = localStorage.getItem("login-details");
    loginData = JSON.parse(loginData);
    setLoginData(loginData);
    return loginData;
  }

  useEffect(() => {
    getLoginData();
  }, []);
  return (
    <Verification
      data={loginData}
      otpInvalid={otpInvalid}
      setOtpInvalid={setOtpInvalid}
      otp={OTP}
      setOTP={setOTP}
      handler={loginHandler}
      noResend
      subTitle="Enter your google authenticator 6 digit verification code."
    />
  );
};

export default GoogleAuthenticatorVerification;
