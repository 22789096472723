import React, { useRef, useState, useEffect } from "react";
import { FaArrowDown, FaArrowUp } from "react-icons/fa6";
import classes from "./OrderBook.module.css";
import { layout1, layout2, layout3, more2 } from "assets";
import clsx from "clsx";
import useOnClickOutside from "hooks/useOnClickOutside";
import OptionDropDown from "../OptionDropDown/OptionDropDown";
import { useTheme } from "ThemeContext/ThemeContext";
import { useSelector } from "react-redux";

const dropdownItems = [
  { label: "0.01" },
  { label: "0.1" },
  { label: "10" },
  { label: "100" },
];

const OrderBook = ({
  asks,
  bids,
  tickers,
  market,
  setPrice,
  pricePrecision,
  amountPrecision,
}) => {
  const { isDarkTheme } = useTheme();
  const tabs = [
    { img: layout1, tab: "normal" },
    { img: layout2, tab: "bids" },
    { img: layout3, tab: "asks" },
  ];
  const [activeTab, setActiveTab] = useState("normal");

  const dropdownRef = useRef(null);
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const [selectedValue, setSelectedValue] = useState(dropdownItems[0].label);
  useOnClickOutside(dropdownRef, () => setIsDropdownActive(false));

  const optionsRef = useRef(null);
  const [isOptionsActive, setIsOptionsActive] = useState(false);
  const [selectAmount, setSelectAmount] = useState(false);
  const [selectCumulative, setSelectCumulative] = useState(false);
  const [animation, setAnimation] = useState(false);
  useOnClickOutside(optionsRef, () => setIsOptionsActive(false));

  const listContainerRef = useRef(null);

  useEffect(() => {
    if (listContainerRef.current) {
      listContainerRef.current.scrollTop =
        listContainerRef.current.scrollHeight;
    }
  }, [asks, bids, activeTab]);
  // list wrapper width
  const listWrapperRef = useRef(null);
  const [listWrapperWidth, setListWrapperWidth] = useState(0);
  useEffect(() => {
    if (listWrapperRef.current) {
      setListWrapperWidth(listWrapperRef.current.offsetWidth);
    }
  }, [listWrapperRef.current]);
  // adding bg on all asks price
  const totalAskPrice = asks.reduce((sum, item) => sum + item.price, 0);
  const askPercentages = (price) => {
    return (price / totalAskPrice) * 100;
  };

  //adding bg on all bids price
  const totalbidsPrice = asks.reduce((sum, item) => sum + item.price, 0);
  const bidsPercentages = (price) => {
    return (price / totalbidsPrice) * 100;
  };
  return (
    <div className={clsx(classes.wrapper, !isDarkTheme && classes.lightTheme)}>
      <div className={classes.header}>
        <div className={classes.layOutWrapper}>
          {tabs.map((el, i) => (
            <div
              className={clsx(
                classes.layOutIconContainer,
                activeTab === el.tab && classes.acitveLayOut
              )}
              key={i}
              onClick={() => setActiveTab(el.tab)}
            >
              <img src={el.img} alt="#" className={classes.layOutIcon} />
            </div>
          ))}
        </div>

        <div className={classes.filteringWrapper}>
          <div ref={dropdownRef} className={classes.dropdownWrapper}>
            {/* <Dropdown
              isActive={isDropdownActive}
              selectedValue={selectedValue}
              dropdownItems={dropdownItems}
              onClick={() => {
                if (isDropdownActive) {
                  setIsDropdownActive(false);
                } else {
                  setIsDropdownActive(true);
                }
              }}
              isDropdownActive={isDropdownActive}
              onSelect={(val) => {
                setSelectedValue(val);
                setIsDropdownActive(false);
              }}
            /> */}
          </div>

          <div
            className={classes.optionContainer}
            onClick={() => setIsOptionsActive(true)}
          >
            <img src={more2} alt="#" className={classes.option} />
            <div ref={optionsRef}>
              <OptionDropDown
                isActive={isOptionsActive}
                isDropdownActive={isOptionsActive}
                selectAmount={selectAmount}
                setSelectAmount={setSelectAmount}
                selectCumulative={selectCumulative}
                setSelectCumulative={setSelectCumulative}
                animation={animation}
                setAnimation={setAnimation}
                onSelect={() => setIsOptionsActive(false)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={classes.listWrapper} ref={listWrapperRef}>
        <div className={classes.listHeader}>
          <p className={clsx(classes.heading, classes.textLeft)}>
            Price({String(market?.quote_asset)})
          </p>
          <p className={clsx(classes.heading, classes.textLeft)}>
            Quantity({market?.base_asset})
          </p>
          <p className={classes.heading}>Total({market?.quote_asset})</p>
        </div>
        <div
          className={clsx(
            classes.container,
            activeTab === "asks" && classes.container2,
            activeTab === "bids" && classes.container3
          )}
        >
          {(activeTab === "asks" || activeTab === "normal") && (
            <div
              ref={listContainerRef}
              className={clsx(
                classes.listContainer,
                classes.askContainer,
                classes.asks,
                "overflow",
                activeTab === "asks" && classes.fullHeight
              )}
            >
              {asks
                ?.sort((a, b) => b.price - a.price)
                .map((el, i) => (
                  <React.Fragment key={i}>
                    <p
                      onClick={() => {
                        setPrice(el.price);
                      }}
                      className={[
                        classes.price,
                        el.price > 0
                          ? classes.negativePrice
                          : classes.negativePrice,
                      ].join(" ")}
                    >
                      {Number(el.price).toFixed(pricePrecision)}
                    </p>
                    <p className={classes.amount}>
                      {Number(el.amount).toFixed(3)}
                    </p>
                    <p
                      className={classes.total}
                      style={{
                        "--width": `${
                          listWrapperWidth *
                          askPercentages(Number(el.price) / 100)
                        }%`,
                      }}
                    >
                      {Number(el.total).toFixed(3)}
                    </p>
                  </React.Fragment>
                ))}
            </div>
          )}
          <div
            className={clsx(
              classes.spaceBetween,
              (activeTab === "normal" || activeTab === "bids") &&
                classes.marginTop
            )}
          >
            <h3
              className={clsx(
                classes.growth,
                tickers?.last_price > 0
                  ? classes.positiveGrowth
                  : classes.negativeGrowth
              )}
            >
              {tickers?.last_price.toFixed(6)}
              {tickers?.last_price > 0 ? (
                <FaArrowUp className={classes.upArrow} />
              ) : (
                <FaArrowDown className={classes.downArrow} />
              )}
            </h3>
            <p className={classes.more}>More</p>
          </div>
          {(activeTab === "bids" || activeTab === "normal") && (
            <div
              className={clsx(
                classes.listContainer,
                "overflow",
                classes.bids,
                activeTab === "bids" && classes.fullHeight
              )}
            >
              {bids
                ?.sort((a, b) => b.price - a.price)
                .map((el, i) => (
                  <React.Fragment key={i}>
                    <p
                      onClick={() => {
                        setPrice(el.price);
                      }}
                      className={[
                        classes.price,
                        el.price > 0
                          ? classes.positivePrice
                          : classes.positivePrice,
                      ].join(" ")}
                    >
                      {Number(el.price).toFixed(pricePrecision)}
                    </p>
                    <p className={classes.amount}>
                      {Number(el.amount).toFixed(3)}
                    </p>
                    <p
                      className={classes.total}
                      style={{
                        "--width": `${
                          listWrapperWidth *
                          bidsPercentages(Number(el.price) / 100)
                        }%`,
                      }}
                    >
                      {Number(el.total).toFixed(3)}
                    </p>
                  </React.Fragment>
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderBook;
