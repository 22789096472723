import React, { useState, useMemo } from "react";
import Pagination from "common/Pagination/Pagination";
import classes from "./WalletBalanceTable.module.css";

import clsx from "clsx";
import SingleRow from "./SingleRow/SingleRow";
import { useTheme } from "ThemeContext/ThemeContext";

const WalletBalanceTable = ({ data }) => {
  const { isDarkTheme } = useTheme();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * itemsPerPage;
    const lastPageIndex = firstPageIndex + itemsPerPage;
    return data?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, data, itemsPerPage]);

  return (
    <div
      className={clsx(classes.marketTable, !isDarkTheme && classes.lightTheme)}
    >
      <table className={classes.table}>
        <tbody>
          <tr className={classes.row}>
            <th className={clsx(classes.heading, classes.textAlignCenter)}>
              <p className={classes.tableHeading}>Wallet</p>
            </th>
            <th className={classes.heading}>
              <p className={classes.tableHeading}>Total Balance</p>
            </th>
            <th className={classes.heading}>
              <p className={classes.tableHeading}>Available Balance</p>
            </th>

            {/* <th className={classes.heading}>
              <p className={clsx(classes.tableHeading, classes.lastHeading)}>
                Action
              </p>
            </th> */}
          </tr>
          {currentTableData?.map((el, index) => (
            <SingleRow {...el} key={index} index={index} />
          ))}
        </tbody>
      </table>{" "}
      <div className={classes.pagination}>
        <Pagination
          currentPage={currentPage}
          totalCount={data.length}
          pageSize={itemsPerPage}
          onPageChange={(page) => setCurrentPage(page)}
          siblingCount={0}
        />
      </div>
    </div>
  );
};

export default WalletBalanceTable;
