import React, { useRef, useState } from "react";
import Text from "../../common/Text/Text";
import classes from "./PerosonalInformation.module.css";
import Dropdown from "../Dropdown/Dropdown";
import Input from "components/common/Input/Input";
import DatePicker from "components/common/DatePicker/DatePicker";
import Button from "components/Button/Button";
import axios from "axios";
import Cookies from "js-cookie";

const countries = [
  "India"
];

const PerosonalInformation = ({ setStep }) => {
  //nationality dropdown


  const nationalityRef = useRef(null);
  const [nationality, setNationality] = useState("United States");
  const [
    isSelectedNationalityDropdownActive,
    setIsSelectNationalityDropdownActive,
  ] = useState(false);

  //inputpur field state
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  //date of birth
  const [dob, setDob] = useState(null);

  const [panNumber, setPanNumber] = useState("");
  const [occupation, setOccupation] = useState("");



  async function submitPersonalInfo() {
    let api = process.env.REACT_APP_API + "/kyc/update_personal_info";
    let body = {
      nationality: nationality,
      legal_name: firstName + " " + lastName,
      date_of_birth: dob,
      pan: panNumber,
      occupation: occupation,
    }

    let update = await axios.post(api, body, {
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + Cookies.get("auth-token")
      }
    });

    if (update.status === 200) {
      setStep((prev) => prev + 1);
    }
  }

  const handleDateChange = (date) => {
    setDob(date);
  };
  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <Text xl2 colorWhite semiBold>
          Personal Information
        </Text>
        <Text sm>
          Kindly provide the requested information. Please note that your
          information will only be used for verification purposes. Thank you.
        </Text>
      </div>
      <div ref={nationalityRef}>
        <Dropdown
          label="Nationality"
          styled
          isActive={isSelectedNationalityDropdownActive}
          setActive={setIsSelectNationalityDropdownActive}
          selectedValue={nationality}
          onSelect={(val) => {
            setNationality(val);
            setIsSelectNationalityDropdownActive(false);
          }}
          dropdownItems={countries}
          dropdownRef={nationalityRef}
        />{" "}
      </div>{" "}
      <div className={classes.inputWrapper}>
        <Text sm semiBold colorGrey2>
          Legal Name
        </Text>
        <div className={classes.inputContainer}>
          <Input
            type="text"
            placeholder="First Name"
            value={firstName}
            className={classes.input}
            rootClassName={classes.input}
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
          />{" "}
          <Input
            type="text"
            placeholder="Last Name"
            value={lastName}
            rootClassName={classes.input}
            onChange={(e) => {
              setLastName(e.target.value);
            }}
          />
        </div>{" "}
        <Text sm semiBold colorGrey2>
          Same as your Govt. ID or Passport
        </Text>{" "}
      </div>{" "}
      <div className={classes.dateOfBirth}>
        <Text sm semiBold colorGrey2>
          Date Of Birth
        </Text>
        <DatePicker
          placeholder="MM/DD/YYYY"
          selected={dob}
          onChange={handleDateChange}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          dateFormat="dd/MM/yyyy"
        />
      </div>
      


      <div className={classes.inputWrapper}>
        <Text sm semiBold colorGrey2>
        Unique Tax ID
        </Text>
        <div className={classes.inputContainer}>
          <Input
            type="text"
            placeholder="Unique Tax ID"
            value={panNumber}
            className={classes.input}
            rootClassName={classes.input}
            onChange={(e) => {
              setPanNumber(e.target.value);
            }}
          />{" "}
          <Input
            type="text"
            placeholder="Occupation"
            value={occupation}
            rootClassName={classes.input}
            onChange={(e) => {
              setOccupation(e.target.value);
            }}
          />
        </div>{" "}
        <Text sm semiBold colorGrey2>
          {/* You can get your PAN from your PAN card */}
        </Text>{" "}
      </div>{" "}

      <Button
        size="md"
        btnPrimary
        onClick={() => {
           submitPersonalInfo();
          // setStep((prev) => prev + 1);
        }}
        mlAuto
      >
        Next
      </Button>
    </div>
  );
};

export default PerosonalInformation;
