import Verification from "components/Verification/Verification";
import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getUserInfo } from "../../redux/user";
import { toast } from "react-toastify";


const EmailVerification = ({ dispatchAll }) => {
  let dispatch = useDispatch();
  const [loginData, setLoginData] = useState({});
  const [otpInvalid, setOtpInvalid] = useState(false);
  const [OTP, setOTP] = useState("");

  const navigate = useNavigate();
  async function loginHandler(e) {
    e.preventDefault();
    let api = process.env.REACT_APP_API;
    let route = "/auth/login";
    let url = api + route;

    let body = {
      ...loginData,
      mfa: [
        {
          method: "Email",
          data: OTP
        }
      ]
    }


    try {
      let { data, status } = await axios.post(url, body);
      let token = data.token;

      if (status == 200) {
        if (token) {
          Cookies.set("auth-token", token);
          dispatch(getUserInfo());
          dispatchAll();
          navigate("/dashboard")
        }

      } else {
        toast.error("Invalid OTP", {
          position: "top-center",
          theme: "dark",
       
        
        });
        setOtpInvalid(true);
        setOTP("");
      }

    } catch (e) {
      toast.error("Invalid OTP", {
        position: "top-center",
        theme: "dark",
      
      });
      setOTP("");
      setOtpInvalid(true);
    }
  }

  async function dry_validate(otp) {
    let api = process.env.REACT_APP_API;
    let route = "/auth/dry-validate";
    console.log(loginData)
    let body = {
      action: "login",
      submitted_mfa: [
        {
          method: "Email",
          data: otp,
        }
      ]
    };
    try {
      let { data, status } = await axios.post(api + route, body, {
        headers: {
          Authorization: "Bearer " + Cookies.get("auth-token"),
        },
      });
      console.log(data);
      if (status == 200) {
        return 200;
      }
    } catch (e) {
      return 400;
    }
  }

  async function getLoginData() {
    let loginData = localStorage.getItem("login-details");
    loginData = JSON.parse(loginData);
    setLoginData(loginData);
    return loginData;
  }

  useEffect(() => {
    getLoginData();
  }, []);
  return (
    <Verification
      // validator={dry_validate}
      data={loginData}
      handler={loginHandler}
      otp={OTP}
      setOTP={setOTP}
      setOtpInvalid={setOtpInvalid}
      subTitle="We sent a 6 digit verification code to your email. Please check your email." />
  );
};

export default EmailVerification;
