import React from "./BuySellModal.module.css";

import BuySell from "components/Spot/BuySell/BuySell";
import clsx from "clsx";
import classes from "./BuySellModal.module.css";
import { useTheme } from "ThemeContext/ThemeContext";

const BuySellModal = ({
  isActive,
  onClose,
  onlyBuyModal,
  onlySellModal,
  askSideAmount,
  pair,
  bidSideAmount,
  setAskSideAmount,
  setBidSideAmount,
  price,
  setPrice
}) => {
  const { isDarkTheme } = useTheme();

  return (
    <>
      <div
        className={clsx(
          isActive && classes.active,
          classes.modalOverlay,
          !isDarkTheme && classes.lightTheme
        )}
        onClick={onClose}
      />
      <div
        className={clsx(
          isActive && classes.active,

          classes.modal
        )}
      >
        <BuySell
        price={price}
        setPrice={setPrice}
          bidSideAmount={bidSideAmount}
          setBidSideAmount={setBidSideAmount}
          setAskSideAmount={setAskSideAmount}
          askSideAmount={askSideAmount}
          pair={pair}
          onClose={onClose}
          onlyBuyModal={onlyBuyModal}
          onlySellModal={onlySellModal}
        />
      </div>
    </>
  );
};

export default BuySellModal;
