import React from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

import classes from "./SingleRow.module.css";
import clsx from "clsx";
import { useTheme } from "ThemeContext/ThemeContext";
import { parseTickers } from "../../../../../redux/tickers";
import { useSelector } from "react-redux";

const SingleRow = ({ props }) => {
  console.log(props);
  let {
    pair,
    side,
    price,
    quantity,
    timestamp,
    total,
    role,
    showDetails,
    setShowDetails,
    id,
    fee,
  } = props;

  //   {
  //     "id": "17078674676584173314049379989997154",
  //     "pair": "UNSUSDT",
  //     "side": "Ask",
  //     "price": 120000000,
  //     "quantity": 100000000,
  //     "timestamp": 1707867467658417,
  //     "role": "maker",
  //     "fee": 0,
  //     "total": 1.2
  // }
  const { isDarkTheme } = useTheme();
  let markets = useSelector(parseTickers);
  let market = markets.find((market) => market.market_id === pair);

  let date = new Date(timestamp / 1000).toLocaleString();

  let trades_amount = quantity * price;
  // 0.05% fee
  return (
    <>
      <div
        className={clsx(
          classes.row,
          showDetails && classes.active,
          !isDarkTheme && classes.lightTheme
        )}
      >
        <p className={classes.item}> {role?.toUpperCase()}</p>
        <p className={classes.item}>{date}</p>
        <p className={clsx(classes.item, classes.tradeInUsdt)}>
          {(quantity / Math.pow(10, 8)).toFixed(2)}
        </p>

        {showDetails === id ? (
          <FaAngleUp
            className={classes.arrow}
            color="#fff"
            onClick={() => {
              if (showDetails) {
                setShowDetails(false);
              } else {
                setShowDetails(true);
              }
            }}
          />
        ) : (
          <FaAngleDown
            className={classes.arrow}
            onClick={() => {
              setShowDetails(id);
            }}
          />
        )}
      </div>
      {showDetails === id && (
        <div className={classes.infoContainer}>
          <div className={classes.spaceBetween}>
            <p className={classes.item}>Type</p>
            <p className={classes.value}>{"SPOT"}</p>
          </div>
          <div className={classes.spaceBetween}>
            <p className={classes.item}>Side</p>
            <p
              className={clsx(
                classes.value,
                side.toLowerCase() === "buy" && classes.buy,
                side.toLowerCase() === "sell" && classes.sell
              )}
            >
              {side}
            </p>
          </div>
          <div className={classes.spaceBetween}>
            <p className={classes.item}>Price</p>
            <p className={classes.value}>
              {price / 100000000} {market?.quote_asset}
            </p>
          </div>
          <div className={classes.spaceBetween}>
            <p className={classes.item}>Amount</p>
            <p className={classes.value}>
              {" "}
              {quantity / 100000000} {market?.base_asset}
            </p>
          </div>
          <div className={classes.spaceBetween}>
            <p className={classes.item}>Fee</p>
            <p className={classes.value}>
              {" "}
              {fee} {side == "buy" ? market?.base_asset : market?.quote_asset}
            </p>
          </div>{" "}
          <div className={classes.spaceBetween}>
            <p className={classes.item}>Total</p>
            <p className={classes.value}>
              {" "}
              {total} {market?.quote_asset}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default SingleRow;
