import Dropdown from "components/Dropdown/Dropdown";

import classes from "./CreateNewStake.module.css";
import React, { useEffect, useRef, useState } from "react";
import CurrencyDropdown from "components/common/CurrencyDropdown/CurrencyDropdown";
import { bitcoin, tether } from "assets";
import { checkIfNumber } from "components/utils/isNumber";
import Input from "components/common/Input/Input";
import Button from "components/Button/Button";
import clsx from "clsx";
import CreateStakeModal from "modals/CreateStakeModal/CreateStakeModal";
import { useSelector } from "react-redux";
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { useTheme } from "ThemeContext/ThemeContext";

const CreateNewStake = ({
  durations,
  stakingInstances,
  balance,
  setSeletedCurrency,
  selectedCurrency,
  stakeCurrencies,
}) => {
  const { isDarkTheme } = useTheme();
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const dropdownRef = useRef(null);
  const [amount, setAmount] = useState("");
  let [apy, setAPY] = useState("");

  async function apyProcessor() {
    if (!selectedDuration) {
      setAPY(0);
    } else {
      let apy = selectedDuration?.apy;
      setAPY(apy);
    }
  }

  //durationDropdown
  const durationDropdownRef = useRef();
  const [isDurationDropdownActive, setIsDurationDropdownActive] =
    useState(false);
  const [selectedDuration, setSelectedDuration] = useState("");

  //modal
  const [isCreateStakeModalActive, setIsCreateStakeModalActive] =
    useState(false);

  // useEffect(() => {
  //   apyProcessor();
  // }, [selectedCurrency, selectedDuration])

  const [stakingInstance, setStakingInstance] = useState({});

  async function parseDetails() {
    let instance = stakingInstances.find(
      (instance) =>
        instance.token == selectedCurrency?.id &&
        instance.duration == selectedDuration?.duration
    );
    setStakingInstance(instance);
  }

  useEffect(() => {
    parseDetails();
  }, [selectedCurrency, selectedDuration]);

  async function stake() {
    if (amount < stakingInstance.min_amount) {
      alert("Min amount not reached");
      return;
    }
    if (amount > stakingInstance.max_amount) {
      alert("Max amount reached");
      return;
    }

    try {
      console.log(stakingInstance.id);
      let api = process.env.REACT_APP_API;
      let route = "/staking/stake";

      let url = api + route;

      const { data, status } = await axios.post(
        url,
        {
          amount: Number(amount),
          staking_instance_id: stakingInstance.id,
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("auth-token")}`,
          },
        }
      );

      if (status == 200) {
        toast.success("Staked successfully", {
          toastId: "stake-success",
          position: "top-center",
          theme: "dark",
        });
        setTimeout(() => {
          // window.location.reload();
        }, 3000);
      }
    } catch (e) {}
  }

  return (
    <>
      <div
        className={clsx(classes.wrapper, !isDarkTheme && classes.lightTheme)}
      >
        <h2 className={classes.heading}>Create new stake</h2>
        <div className={classes.inputContainer}>
          <p className={classes.label}>Enter Staking Amount</p>

          <div className={classes.inputAndDropdown}>
            <div className={classes.inputAndMax}>
              <Input
                rootClassName={classes.input}
                onKeyDown={(event) => checkIfNumber(event)}
                type="number"
                placeholder="10-50,000"
                value={amount}
                onChange={(e) => {
                  setAmount(e.target.value);
                }}
              />
              <button
                onClick={() => setAmount(balance)}
                className={classes.max}
              >
                Max
              </button>
            </div>
            <div ref={dropdownRef} className={classes.dropdownContainer}>
              <CurrencyDropdown
                isActive={isDropdownActive}
                selectedValue={selectedCurrency}
                setIsActive={setIsDropdownActive}
                onSelect={(val) => {
                  setSeletedCurrency(val);
                  setAPY(0);
                  setIsDropdownActive(false);
                }}
                dropdownItems={stakeCurrencies}
                dropdownRef={dropdownRef}
              ></CurrencyDropdown>
            </div>
          </div>
        </div>

        <div className={classes.durationContainer}>
          <p className={classes.label}>Lock Duration</p>

          <div ref={durationDropdownRef} className={classes.dropdownContainer}>
            <Dropdown
              isActive={isDurationDropdownActive}
              selectedValue={selectedDuration}
              dropdownItems={durations}
              onClick={() => {
                if (isDurationDropdownActive) {
                  setIsDurationDropdownActive(false);
                } else {
                  setIsDurationDropdownActive(true);
                }
              }}
              isDropdownActive={isDropdownActive}
              onSelect={(val) => {
                setSelectedDuration(val);
                setAPY(val.apy);
                setIsDurationDropdownActive(false);
              }}
            />
          </div>
        </div>
        <div className={classes.availableBalanceAndApy}>
          <div className={clsx(classes.spaceBetween, classes.balanceContainer)}>
            <p className={classes.text}>Available Balance</p>
            <h3 className={classes.balance}>
              {balance ? balance?.toFixed(2) : 0.0}{" "}
              <span className={classes.currency}>
                {selectedCurrency?.symbol?.toUpperCase()}
              </span>
            </h3>
          </div>{" "}
          {stakingInstance?.apy && (
            <div className={clsx(classes.spaceBetween, classes.apyContainer)}>
              <p className={classes.text}>APY</p>
              <h3 className={classes.apy}>{stakingInstance?.apy}% </h3>
            </div>
          )}
          {stakingInstance?.min_amount && (
            <div className={clsx(classes.spaceBetween, classes.apyContainer)}>
              <p className={classes.text}>Minimum Staking</p>
              <h3 className={classes.apy}>
                {stakingInstance?.min_amount}{" "}
                {selectedCurrency?.symbol?.toUpperCase()}
              </h3>
            </div>
          )}
          {stakingInstance && (
            <div
              className={clsx(classes.spaceBetween, classes.balanceContainer)}
            >
              <p className={classes.text}>Estimate Earnings</p>
              <h3 className={classes.balance}>
                {(amount * (stakingInstance?.apy / 100)).toFixed(2)}{" "}
                <span className={classes.currency}>
                  {selectedCurrency?.symbol?.toUpperCase()}
                </span>
              </h3>
            </div>
          )}
        </div>
        <Button
          wFull
          btnPrimary
          size="md"
          onClick={() => {
            if (amount < stakingInstance.min_amount) {
              toast.error("Min amount not reached", {
                toastId: "min-amount-not-reached",
                position: "top-center",
                theme: "dark",
              });
              return;
            }
            if (amount > stakingInstance.max_amount) {
              toast.error("Max amount reached", {
                toastId: "max-amount-reached",
                position: "top-center",
                theme: "dark",
              });
              return;
            }

            if (amount > balance) {
              toast.error("Insufficient balance", {
                toastId: "insufficient-balance",
                position: "top-center",
                theme: "dark",
              });
              return;
            }

            if (!stakingInstance) {
              toast.error("Please select a valid staking instance", {
                toastId: "staking-instance-error",
                position: "top-center",
                theme: "dark",
              });
              return;
            }
            setIsCreateStakeModalActive(true);
          }}
        >
          Stake Now
        </Button>
      </div>
      <CreateStakeModal
        selectedCurrency={selectedCurrency}
        stakingInstance={stakingInstance}
        amount={amount}
        selectedDuration={selectedDuration}
        isActive={isCreateStakeModalActive}
        fn={stake}
        onClose={() => setIsCreateStakeModalActive(false)}
      />
    </>
  );
};

export default CreateNewStake;
