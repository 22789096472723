import React, { useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

import { IoMdInformationCircleOutline } from "react-icons/io";

import classes from "./Toolbar.module.css";

import { bitcoin } from "assets";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { parseTickers } from "../../../redux/tickers";
import { useParams } from "react-router-dom";
import { useTheme } from "ThemeContext/ThemeContext";

const Toolbar = ({ tickers, pair }) => {
  const { isDarkTheme } = useTheme();
  let markets = useSelector(parseTickers);
  let currencies = useSelector((state) => state.currencies.currencies);
  let market = markets.find((market) => market?.symbol == pair);
  let currency = currencies.find(
    (curr) => curr.id == market?.base_asset.toLowerCase()
  );

  let original_price = market?.last_price / (1 + market?.change / 100);

  const [showAll, setShowAll] = useState(false);
  return (
    <div className={clsx(classes.wrapper, !isDarkTheme && classes.lightTheme)}>
      <div
        className={classes.currencyWrapper}
        onClick={() => setShowAll((prev) => !prev)}
      >
        <div className={classes.currencyContainer}>
          <img src={currency?.logo} alt="#" className={classes.logo} />
          <h3 className={classes.currencyName}>
            {market?.base_asset}/{market?.quote_asset}
          </h3>
          <a href="#/">
            <IoMdInformationCircleOutline className={classes.infoIcon} />
          </a>
        </div>
        {showAll ? (
          <FaAngleUp className={classes.arrow} />
        ) : (
          <FaAngleDown className={classes.arrow} />
        )}
      </div>

      <div className={clsx(classes.itemsContainer, showAll && classes.showAll)}>
        <div className={classes.item}>
          <p className={classes.text}>Last Price</p>
          <p className={[classes.text, classes.green].join(" ")}>
            {" "}
            <span>
              {tickers?.last_price.toFixed(4)} {market?.quote_asset}
            </span>
          </p>
        </div>
        <div className={classes.item}>
          <p className={classes.text}>24h Change</p>
          <p className={[classes.text, classes.green].join(" ")}>
            {" "}
            <span>{original_price?.toFixed(2)}</span>{" "}
            {tickers?.change?.toFixed(2)}%
          </p>
        </div>
        <div className={classes.item}>
          <p className={classes.text}>24h High</p>
          <p className={[classes.text, classes.highlighted].join(" ")}>
            {tickers?.high?.toFixed(2)} {market?.quote_asset}
          </p>
        </div>{" "}
        <div className={classes.item}>
          <p className={classes.text}>24h Low</p>
          <p className={[classes.text, classes.highlighted].join(" ")}>
            {tickers?.low?.toFixed(2)} {market?.quote_asset}
          </p>
        </div>{" "}
        <div className={classes.item}>
          <p className={classes.text}>24h Volume({market?.base_asset})</p>
          <p className={[classes.text, classes.highlighted].join(" ")}>
            {market?.base_volume?.toFixed(2)}
          </p>
        </div>{" "}
        <div className={classes.item}>
          <p className={classes.text}>24h Volume({market?.quote_asset}) </p>
          <p className={[classes.text, classes.highlighted].join(" ")}>
            {market?.quote_volume?.toFixed(2)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Toolbar;
