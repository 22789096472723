import React from "react";

const Translate = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_320_19774)">
        <path
          d="M3.33325 4.16675H9.16659"
          stroke={color ? color : "#C2C4C4"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.49992 2.5V4.16667C7.49992 7.84833 5.63408 10.8333 3.33325 10.8333"
          stroke={color ? color : "#C2C4C4"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.16675 7.5C4.16675 9.28667 6.62675 10.7567 9.75008 10.8333"
          stroke={color ? color : "#C2C4C4"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 16.6667L13.3333 9.16675L16.6667 16.6667"
          stroke={color ? color : "#C2C4C4"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.9167 15H10.75"
          stroke={color ? color : "#C2C4C4"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_320_19774">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Translate;
