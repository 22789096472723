import Button from "components/Button/Button";
import { successIcon } from "assets";
import classes from "./IDVerificationSuccess.module.css";
import Heading from "components/common/Heading/Heading";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const IDVerificationSuccess = () => {
  let navigate = useNavigate();

  return (
    <div className="container">
      <div className={classes.wrapper}>
        <img className={classes.changed} src={successIcon} alt="tick" />
        <div className={classes.textContainer}>
          <Heading
            heading="Verification Successful!"
            success
            lg
            center
            className={classes.heading}
          />
          <p className={classes.text}>
            Your identity verification has been completed successfully. You can
            now access all the features of this platform.
          </p>
        </div>

        <Button
          onClick={() => {
            navigate("/dashboard");
          }}
          className={classes.btn}
          btnGray
          size="lg"
        >
          Back to Dashboard
        </Button>
      </div>
    </div>
  );
};

export const IDVerificationPending = () => {
  let navigate = useNavigate();

  return (
    <div className="container">
      <div className={classes.wrapper}>
        <img className={classes.changed} src={"https://cdn-icons-png.flaticon.com/128/6520/6520458.png"} alt="tick" />
        <div className={classes.textContainer}>
          <Heading
            heading="Verification Pending"
            success
            lg
            center
            className={classes.heading}
          />
          <p className={classes.text}>
          Your KYC is pending for approval. You'll be notified by email once approved.
          </p>
        </div>

        <Button
          onClick={() => {
            navigate("/dashboard");
          }}
          className={classes.btn}
          btnGray
          size="lg"
        >
          Back to Dashboard
        </Button>
      </div>
    </div>
  );
};

export default IDVerificationSuccess;

export const IPWarnings = () => {
  let Navigate = useNavigate();

  return (
    <div className="container">
      <div className={classes.wrapper}>
        <img
          className={classes.changed}
          src={"https://cdn-icons-png.flaticon.com/128/4344/4344882.png"}
          alt="tick"
        />
        <div className={classes.textContainer}>
          <Heading heading="Alert" success lg center />
          <p className={classes.text}>
            We're truly excited to see your interest in UNSXchange! We might
            offer our services to you in future. Thanks for your interest 🙏
          </p>
        </div>

        {/* <Button
          onClick={
            () => {
              localStorage.clear();
              // Navigate("/wallet/spot")
              // window.location.href = ""
            }
          }
          className={classes.btn} btnGray size="lg">
          Contact us
        </Button> */}
      </div>
    </div>
  );
};
