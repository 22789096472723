import React from "react";
import classes from "./Home.module.css";
import HeroSection from "components/Home/HeroSection/HeroSection";
import clsx from "clsx";
import CryptoMarket from "components/Home/CryptoMarket/CryptoMarket";
import NextCryptoVenture from "components/Home/CryptoVenture/CryptoVenture";
import BuySellFromAnyWhere from "components/Home/BuySellFromAnyWhere/BuySellFromAnyWhere";
import CryptoJourney from "components/Home/CryptoJourney/CryptoJourney";
import Faqs from "components/Home/Faqs/Faqs";
import Footer from "components/Footer/Footer";

const Home = () => {
  return (
    <>
      <div className={clsx("container", classes.wrapper)}>
        <HeroSection />
        <NextCryptoVenture />
        {/* <CryptoMarket /> */}
        <BuySellFromAnyWhere />
        <Faqs />
        <CryptoJourney />
      </div>
      <Footer />
    </>
  );
};

export default Home;
