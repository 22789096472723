import React from "react";
import classes from "./HeroSection.module.css";
import Button from "components/Button/Button";
import { spotTrading } from "assets";
import { useNavigate } from "react-router-dom";

const HeroSection = () => {
  const Navigate = useNavigate();
  return (
    <div className={classes.wrapper}>
      <div className={classes.textContainer}>
        <h2 className={classes.heading}>
          {process.env.REACT_APP_EXCHANGE_NAME}
          <span className={classes.highlight}> - The Future of Crypto Trading</span>
        </h2>
        <p className={classes.text}>
          Experience the next generation of cryptocurrency exchange with {process.env.REACT_APP_EXCHANGE_NAME} - secure, fast, and user-friendly.
        </p>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          size="lg"
          btnPrimary
          onClick={() => {
            Navigate("/login")
          }}
          className={classes.button}
        >
          Get Started
        </Button>
        <Button size="lg" btnGray onClick={() => {
          alert("Applications coming soon!")
        }} className={classes.button}>
          Download App
        </Button>
      </div>

      <div className={classes.imgContainer}>
        <img src={process.env.REACT_APP_HERO_IMAGE} alt="#" className={classes.img} />
      </div>
    </div>
  );
};

export default HeroSection;
