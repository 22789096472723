import { useRef, useState } from "react";
import { RxHamburgerMenu } from "react-icons/rx";

import { FaSun, FaMoon } from "react-icons/fa";

import { Link, Outlet } from "react-router-dom";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  userImg,
  verified,
  identityIcon,
  logoutIcon,
  referrelIcon,
  settingsIcon,
  tradingFeesIcon,
  CloseIcon,
} from "assets";

import {
  avatar,
  close,
  DownloadIcon,
  TranslateIcon,
  WalletIcon,
  logo,
  NavNotificationIcon,
  HelpIcon,
} from "assets";
import Button from "components/Button/Button";
import LanguageDropdown from "components/common/LanguageDropdown/LanguageDropdown";
import useOnClickOutside from "hooks/useOnClickOutside";
import classes from "./MainLayout.module.css";
import NotificationDropdown from "./NotificationDropdown/NotificationDropdown";
import UserDropDown from "./UserDropDown/UserDropDown";
import DownloadMobileAppModal from "modals/DowloadMobileApp/DownloadMobileAppModal";
import { useTheme } from "../ThemeContext/ThemeContext";
import { toast } from "react-toastify";

const links =
  process.env.REACT_APP_HIDE_SPOTTRADING == 1
    ? [
        {
          label: "History",
          to: "/history",
        },
      ]
    : [
        {
          label: "Markets",
          to: "/markets",
        },
        {
          label: "Spot Trading",
          to: `/spot/${process.env.REACT_APP_DEFAULT_PAIR}`,
        },
      ];

const authLinks = [
  {
    label: "Dashboard",
    to: "/dashboard",
  },
  {
    label: "History",
    to: "/history",
  },
  {
    label: "Wallet",
    to: "/wallet/overview",
  },
  {
    label: "IEO ",
    to: "/ieo",
  },
  {
    label: "Rewards",
    to: "/rewards",
  },
  {
    label: "Staking",
    to: "/staking",
  },
];

const MainLayout = ({ authenticated }) => {
  const { isDarkTheme, toggleTheme } = useTheme();
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const [selectedLangValue, setSelectedLangValue] = useState("");
  const [isNotificationDropdownActive, setIsNotificationDropdownActive] =
    useState(false);
  const [isUserDropdownActive, setIsUserDropdownActive] = useState(false);
  const [isUserDropdown2Active, setIsUserDropdown2Active] = useState(false);

  const dropdownRef = useRef(null);
  const dropdown2Ref = useRef(null);
  const notifictionDropdownRef = useRef(null);

  const [isDownloadModalShow, setIsDownloadModalShow] = useState(false);
  useOnClickOutside(dropdownRef, () => setIsDropdownActive(false));
  useOnClickOutside(dropdown2Ref, () => setIsDropdownActive(false));
  useOnClickOutside(notifictionDropdownRef, () =>
    setIsNotificationDropdownActive(false)
  );

  let Navigate = useNavigate();

  let user = useSelector((state) => state.user.userInfo);

  return (
    <div
      className={
        isDarkTheme
          ? clsx("dark-theme marginTop", classes.myWrapper)
          : clsx("light-theme marginTop", classes.myWrapper)
      }
    >
      <div
        className={clsx(classes.darkOverlay, isMenuActive && classes.active)}
        onClick={() => setIsMenuActive(false)}
      />
      <div className={clsx(classes.navbar, !isDarkTheme && classes.lightTheme)}>
        <div className={classes.mainLeft}>
          <Link to="/">
            <img width={75} src={process.env.REACT_APP_LOGO} alt="logo" />
          </Link>

          <div className={classes.navigation}>
            {links.map((el, idx) => {
              return (
                <Link
                  className={classes.link}
                  to={el.to}
                  key={"nav-link-" + idx}
                >
                  {el.label}
                </Link>
              );
            })}
          </div>
        </div>
        <div className={clsx(classes.mainRightSm)}>
          <div className={clsx(classes.independentBtns)}>
            {" "}
            {user?.platform_id && (
              <div ref={notifictionDropdownRef}>
                <NotificationDropdown isActive={isNotificationDropdownActive}>
                  <Button
                    iconBtn
                    size="sm"
                    btnBlack={!isNotificationDropdownActive}
                    btnPrimary={isNotificationDropdownActive}
                    onClick={() =>
                      setIsNotificationDropdownActive((prev) => !prev)
                    }
                  >
                    <NavNotificationIcon
                      color={
                        isDarkTheme
                          ? "#C2C4C4"
                          : isNotificationDropdownActive
                          ? "#fff"
                          : "#000"
                      }
                    />
                  </Button>
                </NotificationDropdown>
              </div>
            )}
            <div ref={dropdown2Ref}>
              <LanguageDropdown
                className={classes.languageDropdown}
                isActive={isDropdownActive}
                selectedValue={selectedLangValue}
                left
                onSelect={(val) => {
                  setSelectedLangValue(val);
                  setIsDropdownActive(false);
                }}
              >
                <Button
                  iconBtn
                  size="sm"
                  btnBlack={!isDropdownActive}
                  btnPrimary={isDropdownActive}
                  // onClick={() => setIsDropdownActive(true)}
                  onClick={() =>
                    toast.info("In Other International Languages Soon !")
                  }
                >
                  <TranslateIcon
                    color={
                      isDarkTheme
                        ? "#C2C4C4"
                        : isDropdownActive
                        ? "#fff"
                        : "#000"
                    }
                  />
                </Button>
              </LanguageDropdown>
            </div>
            <Button
              iconBtn
              size="sm"
              btnBlack
              onClick={() => {
                setIsDownloadModalShow(true);
              }}
            >
              {/* <img src={download} alt="download" /> */}
              <DownloadIcon color={isDarkTheme ? "#C2C4C4" : "#000"} />
            </Button>
            <Button
              iconBtn
              size="sm"
              btnBlack
              className={classes.sunMoon}
              onClick={toggleTheme}
            >
              {isDarkTheme ? (
                <FaSun className={classes.sun} />
              ) : (
                <FaMoon className={classes.moon} color="#000" />
              )}
            </Button>
            {user?.platform_id && (
              <>
                {/* <Button iconBtn size="sm" btnBlack>
                  <HelpIcon color={isDarkTheme ? "#C2C4C4" : "#000"} />
                </Button> */}

                <UserDropDown
                  isActive={isUserDropdownActive}
                  onClose={() => setIsUserDropdownActive(false)}
                >
                  <img
                    width={45}
                    src={`https://api.dicebear.com/7.x/adventurer/svg?seed=${user?.platform_id}`}
                    alt="avatar"
                    className={classes.loggedUser}
                    // className={classes.avatar}
                    onClick={() => setIsUserDropdownActive((prev) => !prev)}
                  />
                </UserDropDown>
              </>
            )}
          </div>{" "}
          <RxHamburgerMenu
            className={classes.hamburger}
            onClick={() => setIsMenuActive(true)}
          />
        </div>
        <div
          className={clsx(classes.mainRight, isMenuActive && classes.active)}
        >
          <div className={classes.close} onClick={() => setIsMenuActive(false)}>
            {" "}
            <CloseIcon
              color="#fff"
              // color={isDarkTheme ? "#fff" : "#000"}
              bg={isDarkTheme ? "#222327" : "transparent"}
            />
          </div>
          <div className={clsx(classes.navigation, classes.sm)}>
            {links.map((el, idx) => {
              return (
                <Link
                  to={el.to}
                  className={classes.link}
                  key={"nav-link-" + idx}
                  onClick={() => setIsMenuActive(false)}
                >
                  {el.label}
                </Link>
              );
            })}
          </div>
          <div
            className={clsx(classes.buttons, classes.first)}
            onClick={() => {
              if (isMenuActive) {
                setIsMenuActive(false);
              }
            }}
          >
            {user?.platform_id ? (
              <div className={classes.authenticatedRoutes}>
                {authLinks.map((el, idx) => {
                  return (
                    <Link
                      className={classes.link}
                      to={el.to}
                      key={"nav-link-" + idx}
                    >
                      {el.label}
                    </Link>
                  );
                })}
              </div>
            ) : (
              <>
                <Button
                  size="sm"
                  btnBlack
                  to="/login"
                  onClick={() => setIsMenuActive(false)}
                >
                  Login
                </Button>
                <Button
                  size="sm"
                  btnPrimary
                  to="/signup"
                  onClick={() => setIsMenuActive(false)}
                >
                  Sign up
                </Button>
              </>
            )}
          </div>
          <div className={clsx(classes.buttons, classes.second)}>
            {user.platform_id && (
              <div ref={notifictionDropdownRef}>
                {/* <NotificationDropdown isActive={isNotificationDropdownActive}>
                  <Button
                    iconBtn
                    size="sm"
                    btnBlack={!isNotificationDropdownActive}
                    btnPrimary={isNotificationDropdownActive}
                    onClick={() =>
                      setIsNotificationDropdownActive((prev) => !prev)
                    }
                  >
                    <NavNotificationIcon
                      color={
                        isDarkTheme
                          ? "#C2C4C4"
                          : isNotificationDropdownActive
                          ? "#fff"
                          : "#000"
                      }
                    />
                  </Button>
                </NotificationDropdown> */}
              </div>
            )}
            <div ref={dropdownRef}>
              <LanguageDropdown
                isActive={isDropdownActive}
                selectedValue={selectedLangValue}
                onSelect={(val) => {
                  setSelectedLangValue(val);
                  setIsDropdownActive(false);
                }}
              >
                <Button
                  iconBtn
                  size="sm"
                  btnBlack={!isDropdownActive}
                  btnPrimary={isDropdownActive}
                  // onClick={() => setIsDropdownActive(true)}
                  onClick={() =>
                    toast.info("In Other International Languages Soon !")
                  }
                >
                  <TranslateIcon
                    color={
                      isDarkTheme
                        ? "#C2C4C4"
                        : isDropdownActive
                        ? "#fff"
                        : "#000"
                    }
                  />
                </Button>
              </LanguageDropdown>
            </div>
            <Button
              iconBtn
              size="sm"
              btnBlack
              onClick={() => {
                toast.error("Mobile Apps will be available soon", {
                  position: "top-center",
                  theme: "dark",
                });
                // setIsDownloadModalShow(true)
              }}
            >
              <DownloadIcon color={isDarkTheme ? "#C2C4C4" : "#000"} />
            </Button>{" "}
            <Button
              iconBtn
              size="sm"
              btnBlack
              className={classes.sunMoon}
              onClick={toggleTheme}
            >
              {isDarkTheme ? (
                <FaSun className={classes.sun} />
              ) : (
                <FaMoon className={classes.moon} color="#000" />
              )}
            </Button>
            <Button
              iconBtn
              size="sm"
              btnBlack
              onClick={() => {
                Navigate("/wallet/overview");
              }}
            >
              <WalletIcon color={isDarkTheme ? "#C2C4C4" : "#000"} />
            </Button>
            {user?.platform_id && (
              <>
                {/* <Button iconBtn size="sm" btnBlack>
                  <HelpIcon color={isDarkTheme ? "#C2C4C4" : "#000"} />
                </Button> */}

                <UserDropDown
                  isActive={isUserDropdown2Active}
                  onClose={() => setIsUserDropdown2Active(false)}
                >
                  <img
                    width={45}
                    src={`https://api.dicebear.com/7.x/adventurer/svg?seed=${user?.platform_id}`}
                    alt="avatar"
                    className={classes.loggedUser}
                    // className={classes.avatar}
                    onClick={() => setIsUserDropdown2Active((prev) => !prev)}
                  />
                </UserDropDown>
              </>
            )}
          </div>
        </div>
      </div>
      <DownloadMobileAppModal
        isActive={isDownloadModalShow}
        onClose={() => setIsDownloadModalShow(false)}
      />
      <Outlet />
    </div>
  );
};

export default MainLayout;
