import React from "react";

const NationalIdOrPassportIcon = ({ color, opacity }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
      <g
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        clipPath="url(#a)"
        opacity={opacity}
      >
        <path d="M3 7a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V7Z" />
        <path d="M7 10a2 2 0 1 0 4 0 2 2 0 0 0-4 0ZM15 8h2M15 12h2M7 16h10" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NationalIdOrPassportIcon;
