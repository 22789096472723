import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "pages/Login/Login";
import MainLayout from "layouts/MainLayout";
import Signup from "pages/Signup/Signup";
import VerificationMethod from "pages/VerificationMethod/VerificationMethod";
import EmailVerification from "pages/EmailVerification/EmailVerification";
import PhoneVerification from "pages/PhoneVerification/PhoneVerification";
import GoogleAuthenticatorVerification from "pages/GoogleAuthenticatorVerification/GoogleAuthenticatorVerification";
import ForgotPassword from "pages/ForgotPassword/ForgotPassword";
import VerifyAccount from "pages/VerifyAccount/VerifyAccount";
import SetNewPassword from "pages/SetNewPassword/SetNewPassword";
import PasswordChanged from "pages/PasswordChanged/PasswordChanged";
import VerifyEmail from "pages/VerifyEmail/VerifyEmail";
import SetPassword from "pages/SetPassword/SetPassword";
import Dashboard from "pages/Dashboard/Dashboard";
import MarketsAndSpotTrading from "pages/MarketsAndSpotTrading/MarketsAndSpotTrading";

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { fetchTickers, parseTickers } from "./redux/tickers";
import {
  ParseCurrency,
  fetchCurrencies,
  selectCurrencyPrices,
} from "./redux/currencies";
import { fetchMarkets } from "./redux/markets";
import { fetchBalance } from "./redux/balance";
import { getMyStakes, getStakingInstances } from "./redux/stakingInstances";
import { getUserInfo } from "./redux/user";
import WalletLayout from "layouts/WalletLayout/WalletLayout";
import Wallet from "pages/Wallet/Spot/Spot";
import { fetchNetworks } from "./redux/networks";
import Deposit from "pages/Wallet/Deposit/Deposit";
import Withdraw from "pages/Wallet/Withdraw/Withdraw";
import WalletOverview from "pages/Wallet/WalletOverview/WalletOverview";
import WithDrawSuccess from "pages/Wallet/Withdraw/WithDrawSuccess/WithDrawSuccess";
import SpotPage from "pages/SpotPage/SportPage";
import UserDropDown from "layouts/UserDropDown/UserDropDown";
import NotificationDropdown from "layouts/NotificationDropdown/NotificationDropdown";
import Staking from "pages/Staking/Staking";
import Footer from "components/Footer/Footer";
import StakeCreateSuccessFully from "pages/Staking/StakeCreatedSuccessFully/StakeCreatedSuccessFully";
import Cookies from "js-cookie";
import Home from "pages/Home/Home";
import ScrollToTop from "hooks/ScrollToTopOnRouteChange";
import { getTransactions } from "./redux/external_transactions";
import OrdersPage from "pages/OrderPage/OrdersPage";
import Rewards, { IEODUMMY, SumSubKYC } from "pages/Rewards/Rewards";
import { toast } from "react-toastify";
import { getRewards } from "./redux/reward";
import { useTheme } from "./ThemeContext/ThemeContext";
import { IPWarnings } from "./pages/Wallet/Withdraw/WithDrawSuccess/WithDrawSuccess";
import {
  AML,
  PrivacyPolicy,
  TermsAndConditions,
  RiskDisclosure,
} from "./pages/Rewards/Rewards";
import PaymentMethod from "./pages/PaymentMethod/PaymentMethod";
import ReferAndEarn from "pages/ReferAndEarn/ReferAndEarn";
import LegalPolicyAndTerms from "pages/LegalPolicyAndTerms/LegalPolicyAndTerms";
import axios from "axios";
import IDVerification from "pages/IDVerification/IDVerification";
import IDVerificationSuccess, {
  IDVerificationPending,
} from "pages/IDVerification/IDVerificationSuccess/IDVerificationSuccess";
import AccountSettingsLayout from "layouts/AccountSettingsLayout/AccountSettingsLayout";
import AccountInfo from "pages/AccountSettings/AccountInfo/AccountInfo";
import Security from "pages/AccountSettings/Security/Security";
import Notifications from "pages/AccountSettings/Notifications/Notifications";
import MyDevices from "pages/AccountSettings/MyDevices/MyDevices";
import PrivacySettings from "pages/AccountSettings/PrivacySettings/PrivacySettings";
import ActivityLog from "pages/AccountSettings/ActivityLog/ActivityLog";
function App() {
  const dispatch = useDispatch();

  async function dispatchAll() {
    dispatch(fetchBalance());
    dispatch(getUserInfo());
    dispatch(fetchMarkets());
    dispatch(fetchCurrencies());
    dispatch(fetchTickers());
    dispatch(fetchNetworks());
    dispatch(getStakingInstances());
    dispatch(getTransactions());
    dispatch(getRewards());
    dispatch(getMyStakes());
  }

  async function blockIndia() {
    try {
      let path = window.location.pathname;
      console.log(path, "path");
      if (path == "/unserved-country") return;
      let { data } = await axios.get("https://ipinfo.io");
      let country = data.country;
      console.log(country, "country");
      if (country == "IN") {
        window.location.href = "/unserved-country";
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    blockIndia();
  }, [window.location.pathname]);

  useEffect(() => {
    dispatchAll();
  }, [Cookies.get("auth-token")]);

  // useEffect(() => {
  //   listenForDeposits();
  // }, [Cookies.get("auth-token")]);
  const { isDarkTheme, toggleTheme } = useTheme();
  return (
    <div className={isDarkTheme ? "dark-theme" : "light-theme"}>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route path="verification" element={<IDVerification />} />
            <Route
              path="id-verification-succesfull"
              element={<IDVerificationSuccess />}
            ></Route>
            <Route
              path="id-verification-pending"
              element={<IDVerificationPending />}
            ></Route>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="markets" element={<MarketsAndSpotTrading />} />{" "}
            {/* <Route index element={<Navigate to="/login" replace />} /> */}
            <Route path="login" element={<Login dispatchAll={dispatchAll} />} />
            <Route path="signup/:refferer" element={<Signup />} />
            <Route path="signup" element={<Signup />} />
            <Route path="signup-verify" element={<VerifyEmail />} />
            <Route path="set-password" element={<SetPassword />} />
            <Route
              path="verification-method"
              element={<VerificationMethod />}
            />
            <Route
              path="email-verification"
              element={<EmailVerification dispatchAll={dispatchAll} />}
            />
            <Route
              path="phone-verification"
              element={<PhoneVerification dispatchAll={dispatchAll} />}
            />
            <Route
              path="google-authenticator-verification"
              element={
                <GoogleAuthenticatorVerification dispatchAll={dispatchAll} />
              }
            />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route
              path="verify-account"
              element={
                <VerifyAccount mode={"login"} dispatchAll={dispatchAll} />
              }
            />
            <Route
              path="verify-password-reset"
              element={<VerifyAccount mode={"reset"} />}
            />
            <Route path="set-new-password" element={<SetNewPassword />} />
            <Route
              path="password-changed"
              element={
                <PasswordChanged
                  content={
                    "Your password is changed successfully. You can login with your new password."
                  }
                  heading={"Password Changed"}
                />
              }
            />
            <Route
              path="signup-success"
              element={
                <PasswordChanged
                  content={"Click login below to sign in to your account"}
                  heading={"User Created successfully"}
                />
              }
            />{" "}
            <Route path="/" element={<Home />} />
          </Route>

          <Route path="/" element={<MainLayout authenticated />}>
            <Route path="wallet" element={<WalletLayout />}>
              <Route
                path="wallet"
                element={<Navigate to="/wallet/overview" replace />}
              />
              <Route path="overview" element={<WalletOverview />} />
              <Route path="spot" element={<Wallet name={"spot"} />} />
              <Route path="funding" element={<Wallet name={"funding"} />} />
              <Route path="staking" element={<Wallet name={"staking"} />} />
              <Route
                path="papertrading"
                element={<Wallet name={"papertrading"} />}
              />
              {/* <Route path="funding" element={<Funding />} /> */}
              <Route path="crypto/deposit" element={<Deposit />} />
              <Route path="crypto/withdraw" element={<Withdraw />} />
            </Route>
            <Route path="/spot/:market_pair" element={<SpotPage />}></Route>
            <Route path="/spot" element={<SpotPage />}></Route>
            <Route path="/spot/:pair" element={<SpotPage />}></Route>
            <Route path="/staking" element={<Staking />}></Route>
            <Route
              path="/stake-created-successfully"
              element={<StakeCreateSuccessFully />}
            />
            <Route
              path="wallet/crypto/withdraw/withdraw-success"
              element={<WithDrawSuccess />}
            />{" "}
            <Route path="/history" element={<OrdersPage />} />
            <Route path="/rewards" element={<Rewards />} />
            <Route path="/ieo" element={<IEODUMMY />} />
            <Route path="/kyc/sumsub" element={<SumSubKYC />} />
            <Route path="/unserved-country" element={<IPWarnings />} />
            <Route path="/paymentMethod" element={<PaymentMethod />} />
            <Route path="/refer-and-earn" element={<ReferAndEarn />} />
            <Route
              path="/legalPolicyAndTerms"
              element={<LegalPolicyAndTerms />}
            />
            {process.env.REACT_APP_SHORTNAME == "UNS" && (
              <>
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/aml" element={<AML />}></Route>
                <Route
                  path="/terms-and-conditions"
                  element={<TermsAndConditions />}
                />
                <Route path="/risk-disclosure" element={<RiskDisclosure />} />
              </>
            )}{" "}
            <Route path="settings" element={<AccountSettingsLayout />}>
              <Route path="account-info" element={<AccountInfo />} />
              <Route path="security" element={<Security />} />
              <Route path="notifications" element={<Notifications />} />
              <Route path="my-devices" element={<MyDevices />} />
              <Route path="privacy-settings" element={<PrivacySettings />} />
              <Route path="activity-log" element={<ActivityLog />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
