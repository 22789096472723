import React, { useRef, useState } from "react";

import { FaArrowDown, FaArrowUp } from "react-icons/fa6";

import classes from "./RecentTrades.module.css";
import { layout1, layout2, layout3, more2 } from "assets";
import clsx from "clsx";
import Dropdown from "components/Dropdown/Dropdown";
import useOnClickOutside from "hooks/useOnClickOutside";
import OptionDropDown from "../OptionDropDown/OptionDropDown";
const dropdownItems = [
  {
    label: "0.01",
  },
  {
    label: "0.1",
  },
  {
    label: "10",
  },
  {
    label: "100",
  },
];
const RecentTrades = () => {
  const negativePrice = [
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: -43142.3, amount: 1.31803163, total: 0.02317892 },
  ];
  const positivePrice = [
    { price: 43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: 43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: 43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: 43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: 43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: 43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: 43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: 43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: 43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: 43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: 43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: 43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: 43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: 43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: 43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: 43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: 43142.3, amount: 1.31803163, total: 0.02317892 },
    { price: 19967.98, amount: 1.31803163, total: 0.02317892 },
    { price: 19967.98, amount: 1.31803163, total: 0.02317892 },
    { price: 19967.98, amount: 1.31803163, total: 0.02317892 },
    { price: 19967.98, amount: 1.31803163, total: 0.02317892 },
    { price: 19967.98, amount: 1.31803163, total: 0.02317892 },
    { price: 19967.98, amount: 1.31803163, total: 0.02317892 },
    { price: 19967.98, amount: 1.31803163, total: 0.02317892 },
    { price: 19967.98, amount: 1.31803163, total: 0.02317892 },
    { price: 19967.98, amount: 1.31803163, total: 0.02317892 },
  ];

  const growth = 43144.4;
  const layouts = [layout1, layout2, layout3];
  const [acitveLayOut, setActiveLayout] = useState(0);

  //dropdown state

  const dropdownRef = useRef(null);
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const [selectedValue, setSelectedValue] = useState(dropdownItems[0].label);
  useOnClickOutside(dropdownRef, () => setIsDropdownActive(false));

  //options state
  const optionsRef = useRef(null);
  const [isOptionsActive, setIsOptionsActive] = useState(false);
  const [selectAmount, setSelectAmount] = useState(false);
  const [selectCumulative, setSelectCumulative] = useState(false);
  const [animation, setAnimation] = useState(false);
  useOnClickOutside(optionsRef, () => setIsOptionsActive(false));
  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <div className={classes.layOutWrapper}>
          {layouts.map((layout, i) => (
            <div
              className={clsx(
                classes.layOutIconContainer,
                i === acitveLayOut && classes.acitveLayOut
              )}
              key={i}
              onClick={() => setActiveLayout(i)}
            >
              <img src={layout} alt="#" className={classes.layOutIcon} />
            </div>
          ))}
        </div>

        <div className={classes.filteringWrapper}>
          <div ref={dropdownRef} className={classes.dropdownWrapper}>
            <Dropdown
              isActive={isDropdownActive}
              selectedValue={selectedValue}
              dropdownItems={dropdownItems}
              onClick={() => {
                if (isDropdownActive) {
                  setIsDropdownActive(false);
                } else {
                  setIsDropdownActive(true);
                }
              }}
              isDropdownActive={isDropdownActive}
              onSelect={(val) => {
                setSelectedValue(val);
                setIsDropdownActive(false);
              }}
            />
          </div>

          <div
            className={classes.optionContainer}
            onClick={() => setIsOptionsActive(true)}
          >
            <img src={more2} alt="#" className={classes.option} />

            <div ref={optionsRef}>
              <OptionDropDown
                isActive={isOptionsActive}
                isDropdownActive={isOptionsActive}
                selectAmount={selectAmount}
                setSelectAmount={setSelectAmount}
                selectCumulative={selectCumulative}
                setSelectCumulative={setSelectCumulative}
                animation={animation}
                setAnimation={setAnimation}
                onSelect={(val) => {
                  setIsOptionsActive(false);
                }}
              />
            </div>
          </div>
        </div>
      </div>{" "}
      <div className={[classes.listWrapper].join(" ")}>
        <div className={[classes.listHeader].join(" ")}>
          <p className={[classes.heading, classes.textLeft].join(" ")}>
            Price(USDT)
          </p>
          <p className={[classes.heading, classes.textLeft].join(" ")}>
            Quantity(BTC)
          </p>
          <p className={classes.heading}>Total (BTC)</p>
        </div>
        <div className={[classes.listContainer, "overflow"].join(" ")}>
          {negativePrice.map((el, i) => (
            <React.Fragment key={i}>
              <p
                className={[
                  classes.price,
                  el.price > 0 ? classes.positivePrice : classes.negativePrice,
                ].join(" ")}
              >
                {Math.abs(el.price)}
              </p>{" "}
              <p className={classes.amount}>{el.amount}</p>
              <p className={classes.total}>{el.total}</p>
            </React.Fragment>
          ))}
        </div>{" "}
        <div className={classes.spaceBetween}>
          <h3
            className={clsx(
              classes.growth,
              growth > 0 ? classes.positiveGrowth : classes.negativeGrowth
            )}
          >
            {growth.toLocaleString()}
            {growth > 0 ? (
              <FaArrowUp className={classes.upArrow} />
            ) : (
              <FaArrowDown className={classes.downArrow} />
            )}
          </h3>
          <p className={classes.more}>More</p>
        </div>
        <div className={[classes.listContainer, "overflow"].join(" ")}>
          {" "}
          {positivePrice.map((el, i) => (
            <React.Fragment key={i}>
              <p
                className={[
                  classes.price,
                  el.price > 0 ? classes.positivePrice : classes.negativePrice,
                ].join(" ")}
              >
                {Math.abs(el.price)}
              </p>{" "}
              <p className={classes.amount}>{el.amount}</p>
              <p className={classes.total}>{el.total}</p>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RecentTrades;
